export function clamp(value, min, max) {
  let newVal = value
  if (min !== undefined) {
    newVal = Math.max(min, newVal)
  }
  if (max !== undefined) {
    newVal = Math.min(newVal, max)
  }
  return newVal
}
