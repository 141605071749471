import React, {
  useRef
} from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import { clamp } from 'utils'

import FlexboxRow from 'components/FlexboxRow'

import useOutsideClick from 'hooks/useOutsideClick'
import useDraggable from 'hooks/useDraggable'

const VolumeControlPanelWrapper = styled.div`
  user-select: none;
  position: absolute;
  padding: 8px;
  height: 135px;
  width: 81px;
  bottom: var(--navbar-height);
  background: var(--windows-tan);
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
`

const SliderRangeLine = styled.div`
  width: 1px;
  height: 85%;
  margin-top: 20%;
  margin-left: 35%;
  background: rgb(13, 13, 13);
  border-top: 1px solid rgb(130, 130, 130);
  border-left: 1px solid rgb(130, 130, 130);
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
`

const Slider = styled.div`
  position: absolute;
  width: 21px;
  bottom: ${props => `${props.position}%`};
  background: var(--windows-tan);
  height: 11px;
  cursor: pointer;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
`

const SliderWrapper = styled.div`
  display: inline-block;
  height: 80px;
  width: 30px;
  position: relative;
  border-radius: 3px 0px 0px 3px;
  border: 1px dotted rgb(140, 140, 140);
`

const MuteButton = styled.input.attrs({
  type: 'checkbox'
})`
  margin-left: 11px;
  width: 1rem;
  height: 1rem;
`

const VolumeControlInnerWrapper = styled.div`
  text-align: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
`

function StaticVolumeRange() {
  return (
    <div style={{ display: 'inline-block', height: '80px' }}>
      <div
        style={{
          display: 'inline-block',
          height: '87%',
          borderRight: '1px solid white',
          marginRight: '-6px',
          transform: 'rotate(-9deg)'
        }}
      />
      <div
        style={{
          display: 'inline-block',
          height: '85%',
          marginTop: '49%',
          marginRight: '7px',
          width: '11px',
          borderTop: '1px solid white',
          borderRight: '1px solid rgb(140, 140, 140)'
        }}
      />
    </div>
  )
}

const VOLUME_HIGHEST_POSITION = 82
const VOLUME_LOWEST_POSITION = 0

export default function VolumeControlPanel({
  isMuted = false,
  volumeLevel = 0,
  onChangeVolume = noop,
  onClickMute = noop,
  close = noop
}) {
  const volumeControlRef = useRef(null)
  const sliderWrapperRef = useRef(null)
  const sliderRef = useRef(null)

  useOutsideClick(volumeControlRef, close, { useMouseDown: true })

  useDraggable(sliderRef, { constrainToYAxis: true, maxYValue: 68, minYValue: 0 })

  function handleSliderClick(e) {
     const { top, height } = sliderWrapperRef.current.getBoundingClientRect()

     // Resets slider style so anything set by dragger is cleared
     sliderRef.current.style = null

     const y = e.clientY - top
     const percentage = (height - y) / height
     onChangeVolume(clamp(percentage, 0, 1.0))
  }

  function finishDragging(e) {
    e.stopPropagation()
    const { height } =
    sliderWrapperRef.current.getBoundingClientRect()

    const percentage = (height - sliderRef.current.offsetTop) / height
    onChangeVolume(clamp(percentage, 0, 1.0))
  }

  return (
    <VolumeControlPanelWrapper ref={volumeControlRef}>
      <VolumeControlInnerWrapper onMouseUp={finishDragging}>
        <div>Volume</div>
        <FlexboxRow
          isCentered
          style={{ justifyContent: 'center' }}
        >
          <StaticVolumeRange />
          <SliderWrapper
            ref={sliderWrapperRef}
            onClick={handleSliderClick}
          >
            <SliderRangeLine />
            <Slider
              ref={sliderRef}
              onClick={finishDragging}
              position={clamp(volumeLevel * 100, VOLUME_LOWEST_POSITION, VOLUME_HIGHEST_POSITION)}
            />
          </SliderWrapper>
        </FlexboxRow>
        <FlexboxRow
          isCentered
          style={{ textAlign: 'left' }}
        >
          <MuteButton
            onChange={onClickMute}
            checked={isMuted}
          />
          <span style={{ textDecoration: 'underline' }}>M</span>ute
        </FlexboxRow>
      </VolumeControlInnerWrapper>
    </VolumeControlPanelWrapper>
  )
}
