import styled from 'styled-components'

import MountainGrid from 'assets/img/mountain-grid.svg'

export default styled.img.attrs({
  src: MountainGrid,
  alt: "mountain grid pattern"
})`
  position: absolute;
  bottom: 0;
  width: 100vw;
`
