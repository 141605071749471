import React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

const StyledLocalization = styled.div.attrs({
  className: "Antivirus"
})`
  padding-top: 2px;
  padding-right: 5px;
  opacity: 0.7;

  cursor: pointer;

  span {
    font-weight: bold;
  }
`

export default function Localization({
  language = "DE",
  onClick = noop
}) {
  return (
    <StyledLocalization onClick={onClick}>
      {language === "DE" ? <span>DE</span> : <span>EN</span>}
    </StyledLocalization>
  );
}
