import { useEffect } from 'react'

import { clamp } from '../utils'

export default function useDraggable(elementToMoveRef, options = {
  disableDrag: false
}) {
  useEffect(() => {
    const {
      draggableElementSelector,
      constrainToXAxis,
      constrainToYAxis,
      maxYValue,
      minYValue,
      maxXValue,
      minXValue
    } = options

    if (options.disableDrag) return

    const elementToMove = elementToMoveRef.current

    let pos1 = 0
    let pos2 = 0
    let pos3 = 0
    let pos4 = 0
    if (!elementToMove) return

    if (draggableElementSelector) {
      // if present, the this sub-element is where you move the element from:
      elementToMove.querySelector(draggableElementSelector).addEventListener('mousedown', mouseDragStart)
      elementToMove.querySelector(draggableElementSelector).addEventListener('touchstart', touchDragStart)
    } else {
      // otherwise, move the element from anywhere inside the element:
      elementToMove.addEventListener('mousedown', mouseDragStart)
      elementToMove.addEventListener('touchstart', touchDragStart)
    }

    function mouseDragStart(e) {
      e = e || window.event;
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.addEventListener('mouseup', mouseDragEnd)
      document.addEventListener('mousemove', mouseDrag)
    }

    function mouseDrag(e) {
      e = e || window.event
      e.preventDefault()
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:

      const newX = `${clamp(elementToMove.offsetLeft - pos1, minXValue, maxXValue)}px`

      const newY = `${clamp(elementToMove.offsetTop - pos2, minYValue, maxYValue)}px`

      if (constrainToXAxis) {
        elementToMove.style.left = newX
      } else if (constrainToYAxis) {
        elementToMove.style.top = newY
      } else {
        elementToMove.style.top = newY
        elementToMove.style.left = newX
      }
    }

    function mouseDragEnd() {
      // stop moving when mouse button is released:
      document.removeEventListener('mouseup', mouseDragEnd)
      document.removeEventListener('mousemove', mouseDrag)
    }

    function touchDragStart(e) {
      const [touch] = e.touches
      // get the touch position at startup:
      pos3 = touch.clientX
      pos4 = touch.clientY
      document.addEventListener('touchend', touchDragEnd)
      document.addEventListener('touchmove', touchDrag)
    }

    function touchDrag(e) {
      const [touch] = e.touches
      // calculate the new position:
      pos1 = pos3 - touch.clientX
      pos2 = pos4 - touch.clientY
      pos3 = touch.clientX
      pos4 = touch.clientY

      // set the element's new position:
      const newX = `${clamp(elementToMove.offsetLeft - pos1, minXValue, maxXValue)}px`

      const newY = `${clamp(elementToMove.offsetTop - pos2, minYValue, maxYValue)}px`

      if (constrainToXAxis) {
        elementToMove.style.left = newX
      } else if (constrainToYAxis) {
        elementToMove.style.top = newY
      } else {
        elementToMove.style.top = newY
        elementToMove.style.left = newX
      }
    }

    function touchDragEnd() {
      document.removeEventListener('touchend', touchDragEnd)
      document.removeEventListener('touchmove', touchDrag)
    }

    return () => {
      if (draggableElementSelector) {
        elementToMove.querySelector(draggableElementSelector).removeEventListener('mousedown', mouseDragStart)
        elementToMove.querySelector(draggableElementSelector).removeEventListener('touchstart', touchDragStart)
      } else {
        elementToMove.removeEventListener('mousedown', mouseDragStart)
        elementToMove.removeEventListener('touchstart', touchDragEnd)
      }
    }
  }, [elementToMoveRef, options])
}
