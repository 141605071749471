import React from 'react'
import styled, { css } from 'styled-components'

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isCentered }) => isCentered && css`
    align-items: center;
  `}
`

export default function FlexboxColumn({ children, ...props }) {
  return (
    <Column {...props}>
      {children}
    </Column>
  )
}
