import React from 'react'
import styled from "styled-components"

import ExternalLink from 'components/ExternalLink'
import FlexboxRow from 'components/FlexboxRow'

import Mission_1 from "assets/img/mission-1.png"
import Mission_2 from "assets/img/mission-2.png"
import Mission_3 from "assets/img/mission-3.png"
import Mission_4 from "assets/img/mission-4.png"
import Dominic from "assets/img/team/dominic.jpg"
import Robin from "assets/img/team/robin.jpg"
import Michael from "assets/img/team/michael.jpeg"

const StyledCompanyMission = styled.div.attrs({
  className: "CompanyMission"
})`
  img {
    display: block;
    width: 96%;
    padding: 2%;
  }
`

export function WelcomeSummary({ language }) {
  if (language === "DE") {
    return (
      <React.Fragment>
        Hoppla, was ist jetzt passiert?
        <br />
        <br />
        Sie sind gerade auf der Webseite von capalis gelandet.
        <br />
        <br />
        Herzlich Willkommen! Wir sind eine Personaldienstleistungsagentur mit Sitz in Zürich. Unsere Spezialisierung ist die Vermittlung von IT Spezialisten.
        <br />
        <br />
        Was kann ich machen?
        <br />
        Schau dich doch zuerst mal um, vergiss nicht den Ton einzuschalten! Die Menüführung findest du unter Start.
        <br />
        <br />
        Was hat es mit der Statue auf sich?
        <br />
        Auf der Statue ist Alan Turing zu finden. Turing war ein britischer Mathematiker und Logiker, der einen grossen Einfluss auf die frühere Computerentwicklung erster Stunde hatte. Zudem hatte seine Maschine, die Turing-Bombe, im zweiten Weltkrieg dazu geholfen, den Nachrichtenverkehr der deutschen Wehrmacht zu entschlüsseln. Seinen Namen trägt auch der bekannte <ExternalLink href="https://www.nytimes.com/2019/06/05/obituaries/alan-turing-overlooked.html">Turing Test</ExternalLink>.
        <br />
        <br />
        Stilelemente
        <br />
        Die Webseite basiert auf Stilelementen von <ExternalLink href="https://www.premiumbeat.com/blog/synthwave-vaporwave-visual-styles/">Vaporwave und Synthwave</ExternalLink>.
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        Oops, what happened?
        <br />
        <br />
        You have just landed on the capalis website.
        <br />
        Welcome! We are a recruitment agency based in Zurich. We are specialized in recruiting IT specialists for numerous top companies in Switzerland among all sectors.
        <br />
        <br />
        What can I do?
        <br />
        Take a look around first, don't forget to turn on the sound! You will find the menu by scrolling down to Start.
        <br />
        <br />
        What's with the statue?
        <br />
        The statue is representing Alan Turing. He was a British mathematician and logician who had a great influence on the earlier computer development. In addition, his machine, the Turing bomb, had helped to decipher the German Wehrmacht's message traffic during World War II. His name is also used for the well-known <ExternalLink href="https://www.nytimes.com/2019/06/05/obituaries/alan-turing-overlooked.html">Turing Test</ExternalLink>.
        <br />
        <br />
        Style
        <br />
        The style of this website is based on <ExternalLink href="https://www.premiumbeat.com/blog/synthwave-vaporwave-visual-styles/">Vaporwave and Synthwave</ExternalLink>.
      </React.Fragment>
    )
  }

}

export function WhatWeDoSummary({ language }) {
  if (language === "DE") {
    return (
      <React.Fragment>
        capalis rekrutiert und vermittelt IT Professionals an <span role="img" aria-label="swiss flag">🇨🇭</span> sowie <span role="img" aria-label="german flag">🇩🇪</span> Unternehmen mit dem Ziel, den Markt mit neuen frischen Ideen aufzumischen.
        <br />
        <br />
        Wir sprechen deine Sprache – sei dies Go oder Java – und möchten verstehen, was dich an der Softwareentwicklung fasziniert und warum! Wir wollen dich als .NET Enthusiasten oder Python-Athleten kennenlernen und wissen, was dich bei der Arbeit motiviert und wichtig ist. Basierend darauf finden wir gemeinsam mit dir deinen nächsten Job, bei dem du dein Potential voll ausschöpfen kannst und mit Herzblut dabei bist!
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        capalis recruits and places IT professionals at <span role="img" aria-label="swiss flag">🇨🇭</span> and <span role="img" aria-label="german flag">🇩🇪</span> companies, with the aim of bringing fresh ideas to the market.
        <br />
        <br />
        We speak your language – whether that is Go or Java – and want to understand, what fascinates you about software engineering and why! We want to get to know you as a .NET enthusiast or Python-Athlete and find out, what motivates you at work and what is important to you. Together, we will then find your next job where you can fully live up to your potential and put your heart into.
      </React.Fragment>
    )
  }
}

export function CompanyMissionSummary({ language }) {
  if (language === "DE") {
    return (
      <StyledCompanyMission>
        <h3>Kurze Zusammenfassung</h3>
        Seit über zehn Jahren besteht ein Fachkräftemangel sowie ein Geschlechterunterschied in <ExternalLink href="https://de.wikipedia.org/wiki/MINT-F%C3%A4cher">MINT</ExternalLink> Berufen (Mathematik, Informatik, Naturwissenschaft und Technik). capalis hat sich zum Ziel gesetzt die Geschlechterungleichheit und den Fachkräftemangel in der IT aktiv zu bekämpfen und unterstützt aus diesem Grund einen ambitionierten MINT-Förderverein, der - von den annähernd 1’000 MINT Angeboten in der Schweiz - eine der höchsten Frauenförderquoten aufweist und als einer der effektivsten zur Minderung des ICT/MINT-Fachkräftemangels gilt.
        <br />
        <br />
        Bei jeder erfolgreichen Vermittlung unterstützen wir den Förderverein ICT-Scouts & Campus finanziell und stehen den Talents bei Karrierefragen jederzeit zur Seite und versuchen unkompliziert zu helfen.
        <br />
        <img src={Mission_3} alt="group of young people cheering" />
        <br />
        <br />
        Du möchtest mehr über unsere Beweggründe dahinter erfahren? Dann scrolle einfach hinunter.
        <br />
        <br />
        <h3>Was ist genau Nachhaltigkeit?</h3>
        In einer kürzlich durchgeführten <ExternalLink href="https://www.mckinsey.com/business-functions/sustainability/our-insights/sustainabilitys-deepening-imprint">McKinsey & Company</ExternalLink> Umfrage gaben 70 Prozent der Befragten in Unternehmen an, dass der Thematik Nachhaltigkeit eine aktive Rolle zugeschrieben wird. Nachhaltig zu sein, scheint demnach im Trend zu sein oder hat zumindest eine hohe Relevanz. Aber was genau bedeutet es für ein Unternehmen "nachhaltig" zu sein?
        <br />

        <img src={Mission_1} alt="UN sustainable development goals diagram" />

        <ExternalLink href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/">United Nations</ExternalLink> (UN) identifizierte 17 Bereiche für eine nachhaltige Entwicklung und stellte mit insgesamt knapp 170 Zielen eine der umfassendsten Agendas für die Sicherung einer nachhaltigen Entwicklung auf ökologischer, ökonomischer sowie sozialer Ebene dar.

        Es gibt daher überaus viele Möglichkeiten (für Unternehmen) einen positiven Beitrag zur Erfüllung dieser Ziele beizutragen - zum Beispiel über eigene Handlungen wie die Schaffung einer positiven Unternehmenskultur, aktive Mitarbeiterförderung oder Produktgestaltungen u.v.m. oder fremde Handlungen, bei dem man jemand Drittes beim Erreichen dieser Ziele unterstützt (Vereine, Stiftungen etc.). Wir werden uns im nächsten Abschnitt auf letzteres beschränken (andern helfen anderen zu helfen).

        <h3>Was bedeutet Nachhaltigkeit für uns in der IT Branche?</h3>
        Wir sind ein junges Unternehmen mit Sitz in der Stadt Zürich, welches sich zum Ziel gesetzt hat, IT-Spezialisten mit Unternehmen aus der DACH Region zu verbinden und den Markt der Personaldienstleister aufzumischen. Das Personalwesen ist schon viel zu lange im Winterschlaf und muss sich dem neuen Umfeld anpassen! Gleichzeitig möchten wir eine Vorreiterrolle für ein nachhaltig bewusstes Unternehmen in dieser Branche einnehmen und mit Hilfe unserem Unternehmen einen positiven Einfluss auf unsere Umwelt haben! Wir möchten nicht bloss Missstände aufzeigen oder interessante Linkedin Beiträge veröffentlichen, sondern wollen eine aktive Rolle in der Verbesserung des Status Quo erreichen!

        Wir haben uns daher gefragt, wie wir die Ziele der UN bestmöglichst in den Kontext der IT übertragen können. Da wir uns seit jeher im IT Markt bewegen, kennen wir die zwei langwierigen Problemfelder:
        <br />
        <br />
        Issue 1: Geschlechterunterschiede
        <br />
        <br />
        Der <ExternalLink href="https://data.worldbank.org/indicator/SL.TLF.TOTL.FE.ZS">Weltbank</ExternalLink> zufolge machen Frauen rund 40% der gesamten weltweiten Arbeitnehmerschaft aus (CH 2019: 47%). In der digitalen Wirtschaft beträgt der Anteil von Frauen gemäss dem Digital Scoreboard der <ExternalLink href="https://ec.europa.eu/digital-single-market/en/news/women-digital-scoreboard-2019-country-reports">Europäischen Kommission</ExternalLink> lediglich rund 15%. Frauen sind demnach proportional gesehen klar untervertreten. Des Weiteren kann seit Jahren ein signifikantes geschlechtsspezifisches Lohngefälle festgestellt werden. Die in dieser Branche vorherrschenden Geschlechterunterschiede, zeigen mit den UN Zielen 5 und 10 (Gender Equality & Reduced Inequalities) ein klares Förderpotential auf.
        <br />
        <br />
        Issue 2: Fachkräftemangel
        <br />
        Seit über zehn Jahren besteht ein <ExternalLink href="https://www.stellenmarktmonitor.uzh.ch/de/indices/fachkraeftemangel.html">Fachkräftemangel in MINT Berufen</ExternalLink>, insbesondere in der <ExternalLink href="https://www.ict-berufsbildung.ch/themen/news/detail/n-n/neue-ict-fachkraeftestudie-bedarfsprognose-zusaetzlicher-fachkraeftebedarf-von-40000-bis-ins-jahr/">IT</ExternalLink>. Dieser Nachfrageüberschuss wird sich in den nächsten Jahren, durch die vom <ExternalLink href="https://www.weforum.org/agenda/2016/01/the-fourth-industrial-revolution-what-it-means-and-how-to-respond/">World Economic Forum</ExternalLink> prognostizierten vierten industriellen Revolution, weiter verschärfen, bei dem Durchbrüche in neuen Technologien in Bereichen wie Robotik, AI, AR/VR/MR, Nanotechnologie, Quantencomputer, Biotechnologie, IoT, Blockchain, 5G, Cognitive / Cloud Computing, autonomes fahren u.v.m. die treibende Kraft für die digitale Transformation sein wird. Die Nachfrage wird demnach stetig ansteigen.
        <br />
        <br />
        Mit einer Zuwanderungsrate von <ExternalLink href="https://www.ict-berufsbildung.ch/themen/news/detail/n-n/neue-ict-fachkraeftestudie-bedarfsprognose-zusaetzlicher-fachkraeftebedarf-von-40000-bis-ins-jahr/">20%</ExternalLink> ist die landeseigene Ausbildung von IT Spezialisten der grösste Treiber beim Decken dieses Nachfrageüberschusses. Unser Bildungssystem steht aufgrund dieser digitalen Transformation gemäss dem <ExternalLink href="https://www.sbfi.admin.ch/sbfi/de/home/bfi-politik/bfi-2021-2024/transversale-themen/digitalisierung-bfi.html">Staatssekretariat für Bildung, Forschung und Innovation SBFI</ExternalLink> aktuell vor einigen Herausforderungen. Dass die derzeitigen Bildungssysteme die Kinder unzureichend auf die Berufe von morgen vorbereitet, wird ebenfalls von <ExternalLink href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/digital-blog/reinventing-schools-for-the-digital-age">McKinsey & Company</ExternalLink> und dem <ExternalLink href="https://www.weforum.org/agenda/2017/01/ways-to-prepare-kids-for-jobs-of-future">World Economic Forum</ExternalLink> bestätigt. Eine solche Situation ist für alle Beteiligten nicht einfach - insbesondere für die direkt betroffenen Schülerinnen und Schüler sowie die Lehrpersonen. Letzteren fehlt es oftmals an nötigen Aus- und Weiterbildungen, um den neuen Anforderungen gerecht zu werden. Aktuell sind die Schulsysteme der DACH Region gut aufgestellt, werden aber in den nächsten Jahren durch die digitale Transformation grosse Herausforderungen antreffen, sodass das UN Ziel 4 (Quality Education) behandelt wird.
        <br />
        <br />
        <h3>Unser Beitrag</h3>
        capalis hat sich zum Ziel gesetzt die Geschlechterungleichheit und den Fachkräftemangel in der IT aktiv zu bekämpfen und unterstützt aus diesem Grund einen ambitionierten MINT Förderverein, der - von den annähernd 1’000 MINT Angeboten in der Schweiz - eine der höchsten Frauenförderquoten aufweist und als einer der effektivsten zur Minderung des ICT/MINT-Fachkräftemangels gilt.

        <img src={Mission_2} alt="50/50 percentage split between males and females" />
        <br />
        <blockquote>
          "Wenn wir 50% des Talentpools ausschliessen, ist es kein Wunder, dass wir uns in einem War of Talents befinden"
        </blockquote>
        <br />

        Der <ExternalLink href="https://ict-scouts.ch/">Förderverein ICT-Scouts & Campus</ExternalLink> findet mit flächendeckenden Workshops Informatik Talente direkt an den Volksschulen durch ICT Scouts und fördern diese kontinuierlich und individuell im ICT Campus - ähnlich wie im Sport. Im ICT Campus treffen sich alle gescouteten ICT Talente, um an den verschiedensten Projekten zu arbeiten von Roboter bauen über eigene Games entwickeln, Platinen löten, Websites erstellen, für Wettbewerbe trainieren und vielen anderen kreativen Ideen bis hin zur Vorbereitung für Wettbewerbe.
        <br />
        <br />
        Der Muster-Campus ist 600 m2 gross und befindet sich im alten Möbel Hubacher Gebäude in Muttenz. Weitere zehn Campus Standorte, verteilt über die ganze Schweiz, werden in den nächsten vier Jahren eröffnet und sind dann Heimat von über 2’000 ICT Talenten. Ziel und Zweck des dreijährigen schulbegleitenden Campus ist es, den Jugendlichen die Bandbreite der beruflichen Möglichkeiten aufzuzeigen, Interessen individuell zu fördern und sie auf die Anforderungen der verschiedenen Berufsbilder realitätsnah einzustimmen und vorzubereiten.

        <img src={Mission_4} alt="young people working on a creative activity together" />
      </StyledCompanyMission>
    )
  } else {
    return (
      <StyledCompanyMission>
        <h3>Short summary</h3>
        For more than ten years there has been a shortage of skilled workers and a gender gap in <ExternalLink href="https://de.wikipedia.org/wiki/MINT-F%C3%A4cher">MINT</ExternalLink> professions(mathematics, IT, natural sciences and technology).capalis has set itself the goal of actively combating gender inequality and the lack of skilled workers in IT and for this reason supports an ambitious MINT promotion association which - of the almost 1, 000 MINT offers in Switzerland - has one of the highest promotion rates for women and is considered one of the most effective in reducing the ICT / MINT skills shortage.
        <br />
        <br />
        For every successful placement, we support the ICT - Scouts & Campus Association financially with a fixed amount and are always available to help the talents with career questions and try to help in an uncomplicated way.
        <br />
        <img src={Mission_3} alt="group of young people cheering" />
        <br />
        <br />
        Would you like to learn more about our motives behind this ? Then simply scroll down.
        <br />
        <br />
        <h3>What exactly is sustainability?</h3>
        In a recent <ExternalLink href="https://www.mckinsey.com/business-functions/sustainability/our-insights/sustainabilitys-deepening-imprint">McKinsey & Company</ExternalLink> survey, 70 percent of the surveyed companies stated that the topic of sustainability is attributed an active role. According to this, being sustainable seems to be a trend or at least has a high relevance. But what exactly does it mean for a company to be "sustainable" ?

        <img src={Mission_1} alt="UN sustainable development goals diagram" />

        The <ExternalLink href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/">United Nations</ExternalLink> (UN) identified 17 areas for sustainable development and, with a total of almost 170 goals, represented one of the most comprehensive agendas for ensuring sustainable development on an ecological, economic and social level.

        There are therefore many opportunities(for companies) to make a positive contribution to the achievement of these goals - for example, through their own actions such as the creation of a positive corporate culture, active employee development or product design, etc., or through external actions in which they support someone else in achieving these goals(associations, foundations, etc.). In the next section we will restrict ourselves to the latter(helping others to help others).
        <br />

        <h3>What does sustainability mean for us in the IT industry?</h3>
        We are a young company based in the city of Zurich, which has set itself the goal of connecting IT specialists with companies from the DACH region and of stirring up the market of personnel service providers. 
        Many recruiting firms have been hibernating for far too long and has to adapt to the new environment! At the same time, we would like to take a pioneering role for a sustainably conscious company in this sector and, with the help of our company, have a positive impact on our environment! We don't just want to point out grievances or publish interesting Linkedin articles, but want to play an active role in improving the status quo!

        Therefore we asked ourselves how we can best transfer the goals of the UN into the context of IT. Since we have always been active in the IT market, we know the two long-standing problem areas:
        <br />
        <br />
          Issue 1: Gender differences
        <br />
        <br />
        According to the <ExternalLink href="https://data.worldbank.org/indicator/SL.TLF.TOTL.FE.ZS">World Bank</ExternalLink>, women make up around 40% of the total global workforce (CH 2019: 47%). In the digital economy, according to the <ExternalLink href="https://ec.europa.eu/digital-single-market/en/news/women-digital-scoreboard-2019-country-reports">European Commission's Digital Scoreboard</ExternalLink>, the proportion of women is only around 15%. Women are thus proportionally clearly underrepresented. Furthermore, a significant gender pay gap has been evident for years. The gender differences prevailing in this sector, with UN goals 5 and 10 (Gender Equality & Reduced Inequalities), show a clear potential for promotion.
        <br />
        <br />
        Issue 2: Shortage of skilled workers
        <br />
        For more than ten years there has been a shortage of <ExternalLink href="https://www.stellenmarktmonitor.uzh.ch/de/indices/fachkraeftemangel.html">skilled workers in MINT professions</ExternalLink>, especially in <ExternalLink href="https://www.weforum.org/agenda/2016/01/the-fourth-industrial-revolution-what-it-means-and-how-to-respond/">IT</ExternalLink>. This excess demand will be further exacerbated in the coming years by the fourth industrial revolution predicted by the <ExternalLink href="https://www.weforum.org/agenda/2016/01/the-fourth-industrial-revolution-what-it-means-and-how-to-respond/">World Economic Forum</ExternalLink>, in which breakthroughs in new technologies in areas such as robotics, AI, AR/VR/MR, nanotechnology, quantum computing, biotechnology, IoT, blockchain, 5G, cognitive / cloud computing, autonomous driving, and many more will be the driving force for the digital transformation. Demand will therefore increase steadily.
        <br />
        <br />
        With an immigration rate of <ExternalLink href="https://www.ict-berufsbildung.ch/themen/news/detail/n-n/neue-ict-fachkraeftestudie-bedarfsprognose-zusaetzlicher-fachkraeftebedarf-von-40000-bis-ins-jahr/">20%</ExternalLink>, the country's own training of IT specialists is the biggest driver in meeting this excess demand. According to the <ExternalLink href="https://www.sbfi.admin.ch/sbfi/de/home/bfi-politik/bfi-2021-2024/transversale-themen/digitalisierung-bfi.html">State Secretariat for Education, Research and Innovation SERFI</ExternalLink>, our education system is currently facing several challenges as a result of this digital transformation. The fact that current education systems do not adequately prepare children for the professions of tomorrow is also confirmed by <ExternalLink href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/digital-blog/reinventing-schools-for-the-digital-age">McKinsey & Company</ExternalLink> and the <ExternalLink href="https://www.weforum.org/agenda/2017/01/ways-to-prepare-kids-for-jobs-of-future">World Economic Forum</ExternalLink>. Such a situation is not easy for everyone involved - especially for the students and teachers directly affected. The latter often lack the necessary training and further education to meet the new requirements. Currently, the school systems in the DACH region are well positioned, but will face major challenges in the coming years due to the digital transformation, so that UN Goal 4 (Quality Education) will be addressed.
        <br />
        <br />
        <h3>Our contribution</h3>
          capalis has set itself the goal of actively combating gender inequality and the lack of skilled workers in IT and for this reason supports an ambitious MINT support association, which - of the almost 1,000 MINT offers in Switzerland - has one of the highest rates of support for women and is considered one of the most effective in reducing the ICT/MINT skills shortage.
          <br />
        <img src={Mission_2} alt="50/50 percentage split between males and females" />

          <blockquote>
          "If we exclude 50% of the talent pool, it is no wonder that we are in a talent war"
          </blockquote>
          <br />
        
          The <ExternalLink href="https://ict-scouts.ch/">ICT-Scouts & Campus</ExternalLink> support association finds talented students with comprehensive workshops directly at elementary schools through ICT Scouts and supports them continuously and individually in the ICT Campus - similar to sports. All scouted ICT talents meet at the ICT Campus to work on a wide variety of projects ranging from robot building to developing their own games, soldering circuit boards, creating websites, training for competitions and many other creative ideas to preparing for competitions.
          <br />
          <br />
          The sample campus is 600 m2 large and is located in the old Möbel Hubacher building in Muttenz. A further ten campus locations, spread throughout Switzerland, will open in the next four years and will then be home to over 2,000 ICT talents. The aim and purpose of the three-year campus, which runs parallel to schooling, is to show young people the range of career opportunities, to promote their interests individually and to prepare them realistically for the requirements of the various job profiles.

        <img src={Mission_4} alt="young people working on a creative activity together" />
        </StyledCompanyMission>
    )
  }
}

const StyledWhoWeAre = styled.div.attrs({
  className: "StyledWhoWeAre"
})`
  img {
    display: inline-block;
    width: 30%;
    height: intrinsic;
    padding: 2%;
  }
`

export function WhoWeAreSummary({ language }) {
  if (language === "DE") {
    return (
      <StyledWhoWeAre>
        capalis besteht aus einem interdisziplinären Team aus Informatikern, HR-Experten und Unternehmern, welche es sich zum Ziel gesetzt haben, den Menschen im Rekrutierungsprozess ins Zentrum zu stellen. Wir möchten auf die Bedürfnisse unserer Kandidaten eingehen und Ihnen auf dem Weg zum nächsten Karriereschritt als unterstützende Berater zur Seite stehen. Mit unseren Kunden auf Unternehmensseite pflegen wir partnerschaftliche und enge Beziehungen, damit wir für beide Seiten einen wahren Mehrwert schaffen können und talentierten IT Fachkräften zu einer spannenden, herausfordernden Tätigkeit verhelfen können. Wir sind ein lokales Unternehmen mit einem breiten Spektrum an Angeboten, welche dank unseres umfassenden Netzwerkes vom Schweizer Grossunternehmen in Zürich bis zum hidden champion im Berner Oberland reicht.
        <br />
        <br />
        Robin hat im Anschluss an seine Berufstätigkeit als Bauzeichner mittels der Passerelle die eidgenössische Matur nachgeholt. Dabei verfolgte er stets das Ziel, in Zukunft selbst ein Unternehmen mit aufzubauen und absolvierte aus dieser Motivation das Betriebswirtschaftsstudium an der Universität St. Gallen (HSG). Nach dem Studium arbeitete er über mehrere Jahre in der Personaldienstleistungs- wie Beratungsbranche und konnte mittels verschiedenen Positionen wertvolle Erfahrungen sammeln.
        <br />
        <br />
        Dominic ist nach seinem Betriebswirtschaftsstudium an der Universität Bern in die IT Personalberatung eingestiegen und hat so seine Leidenschaft für Menschen und sein Interesse für Technik zum Beruf gemacht. Nun kann er bereits auf mehrjährige Erfahrung nicht nur in der Personalberatung, sondern auch auf interner Seite in der Personalabteilung von Unternehmen zurückblicken. Dank diesen wertvollen Erfahrungen verfügt er nun über einen 360 Grad Rundumblick und versteht es ausgezeichnet, die Bedürfnisse und Interessen der Kandidaten mit deren der Unternehmen zusammenzubringen.
        <br />
        <br />
        Michael hat im Anschluss an seine Berufstätigkeit als Informatiker (Fachrichtung Systemtechnik) die Berufsmaturität nachgeholt und vertiefte danach seine technischen Fähigkeiten in einem Studium der Wirtschaftsinformatik an der ZHAW. Mit dieser langjährigen Ausbildung und den praktischen Kenntnissen verfügt er einen peripheren Einblick der IT Branche. Durch seinen technischen Hintergrund und dem verbundenen Branchenwissen möchte er seinen IT Kollegen neue Karrieremöglichkeiten aufzeigen.

        <FlexboxRow>
          <img src={Dominic} alt="Dominic Panholzer" />
          <img src={Robin} alt="Robin Kunz" />
          <img src={Michael} alt="Michael Grüssi" />
        </FlexboxRow>
    </StyledWhoWeAre>)
  } else {
    return (
      <StyledWhoWeAre>
        capalis consists of an interdisciplinary team of computer scientists, HR experts and entrepreneurs who have set themselves the goal of putting people at the centre of the recruitment process. We would like to respond to the needs of our candidates and support them on their way to their next career step as supporting consultants. With our corporate clients, we maintain a close relationship based on partnership and sustainability, so that we can create real added value for both sides and help talented IT professionals to find exciting and challenging work. We are a local company with a wide range of offerings, which, thanks to our extensive network, extends from large Swiss companies in Zurich to hidden champions in the Bernese Oberland.
        <br />
        <br />
        After working as an architectural draftsman, Robin obtained his federal school - leaving certificate by means of the passerelle. He always pursued the goal of helping to build up a company in the future, and it was this motivation that led him to study business administration at the University of St.Gallen(HSG). After his studies he worked for several years in the personnel service and consulting industry and was able to gain valuable experience in various positions.
        <br />
        <br />
        After studying business administration at the University of Berne, Dominic joined the IT personnel consulting business, turning his passion for people and his interest in technology into a career. Now he can already look back on several years of experience not only in personnel consulting, but also internally as a recruiting specialist in the personnel HR deparment of various comapnies. Thanks to this valuable experience, he gained a 360 - degree view and is an excellent partner for the needs and interests of candidates and companies.
        <br />
        <br />
        After working as a computer scientist(specialising in systems engineering), Michael obtained his vocational baccalaureate and then deepened his technical skills by studying business informatics at the ZHAW. With this long - standing training and practical knowledge, he has a peripheral insight into the IT industry. With his technical background and the associated industry knowledge, he would like to show his IT colleagues new career opportunities.

        <FlexboxRow>
          <img src={Dominic} alt="Dominic Panholzer" />
          <img src={Robin} alt="Robin Kunz" />
          <img src={Michael} alt="Michael Grüssi" />
        </FlexboxRow>
      </StyledWhoWeAre>
    )

  }
}
