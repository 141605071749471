import React, {
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react'
import styled, { css } from 'styled-components'
import noop from 'lodash/noop'

import FlexboxRow from 'components/FlexboxRow'

import { Windows95ProgramHeader } from 'components/Windows95Program/Elements'

import {
  WelcomeSummary,
  WhoWeAreSummary,
  WhatWeDoSummary,
  CompanyMissionSummary
} from 'content/ContentSummaries'

import useDraggable from 'hooks/useDraggable'
import useOutsideClick from 'hooks/useOutsideClick'

const Windows95TerminalWrapper = styled.div.attrs({
  className: 'Windows95TerminalWrapper'
})`
  position      : absolute;
  display       : flex;
  flex-direction: column;
  box-sizing    : border-box;
  padding-bottom: 3px;

  z-index       : var(--z-foreground);

  ${({ isFocused }) => isFocused && css`
    z-index     : var(--z-foreground-focused);
  `}

  background    : var(--windows-tan);
  height        : var(--terminal-height-px);
  width         : var(--terminal-width-px);
  top           : var(--initial-top-offset-vh);
  right         : var(--initial-right-offset-px);
  min-width     : var(--terminal-min-width-px);

  --maximized-program-width-vw: 70vw;
  --maximized-program-height: calc(100% - var(--navbar-height));
  --maximized-program-top-offset-vh: 3vh !important;
  --maximized-program-side-offset-vw: 15vw !important;
  --maximized-program-padding-bottom-px: 150px;

  @media screen and (max-width: 411px) {
    --maximized-program-padding-bottom-px: 250px;
    --maximized-program-width-vw: 75vw;
    --maximized-program-side-offset-vw: 12.5vw !important;
  }

  @media screen and (min-width: 768px) {
    --maximized-program-padding-bottom-px: 400px;
  }

  @media screen and (min-width: 1024px) {
    --maximized-program-padding-bottom-px: 250px;
    --maximized-program-side-offset-vw: 25vw !important;
    --maximized-program-width-vw: 50vw;
  }

  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    --maximized-program-width-vw: 60vw;
    --maximized-program-side-offset-vw: 20vw !important;
    --maximized-program-padding-bottom-px: 400px;
  }

  @media screen and (min-width: 1280px) {
    --maximized-program-side-offset-vw: 25vw !important;
    --maximized-program-width-vw: 50vw;
    --maximized-program-padding-bottom-px: 250px;
  }

  @media screen and (min-width: 1440px) {
    --maximized-program-padding-bottom-px: 250px;
  }

  @media screen and (min-width: 1920px) {
    --maximized-program-padding-bottom-px: 300px;
  }

  @media screen and (min-width: 2560px) {
    --maximized-program-padding-bottom-px: 500px;
    --maximized-program-width-vw: 50vw;
    --maximized-program-side-offset-vw: 25vw !important;
  }

  font-family   : 'Inconsolata', monospace;

  a {
    transition  : all 100ms;
    color       : var(--neon-yellow);
    :hover {
      color     : var(--neon-red);
    }
  }

  ${({ offset }) => Boolean(offset) && css`
    top  : ${`calc(var(--initial-top-offset-vh) + (var(--terminal-header-height) * ${offset}))`};
    right: ${`calc(var(--initial-right-offset-px) - (var(--terminal-header-height) * ${offset}))`};
  `}


  ${({ isMaximized }) => isMaximized && css`
    border        : none;
    position      : fixed;
    width         : var(--maximized-program-width-vw);
    height        : var(--maximized-program-height);
    top           : var(--maximized-program-top-offset-vh);
    left          : var(--maximized-program-side-offset-vw);
  `}

  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`

const Body = styled.div.attrs({
  className: 'Windows95Terminal-Body'
})`
  margin       : 3px 3px 0 3px;
  overflow-y   : auto;
  flex         : 1;
  user-select  : text;
  padding      : 1rem;
  overflow-y   : auto;
  width        : calc(100% - 6px);
  box-sizing   : border-box;
  background   : black;
  font-size    : 1rem;
  border-bottom: 1px solid #fff;
  border-right : 1px solid #fff;
  border-left  : 1px solid var(--windows-grey);
  border-top   : 1px solid var(--windows-grey);
  color        : white;
  ${({ isMaximized }) => isMaximized && css`
    height: calc(100% - (var(--terminal-header-height) + var(--terminal-footer-height) + 7px));
    padding-bottom: var(--maximized-program-padding-bottom-px);

    @supports (-moz-appearance: none) {
      :after {
        content: "";
        height : var(--maximized-program-padding-bottom-px);;
        display: block;
        opacity: 0
      }
    }
  `}
`


const Footer = styled.div.attrs({
  className: 'Windows95Terminal-Footer'
})`
  background: var(--windows-tan);
  padding   : 0px 5px;
  box-sizing: border-box;
  height    : var(--terminal-footer-height);
`


const FooterSegment = styled.div.attrs({
  className: 'Windows95Terminal-FooterSegment'
})`
  border-bottom: 1px solid #fff;
  border-right : 1px solid #fff;
  border-left  : 1px solid var(--windows-grey);
  border-top   : 1px solid var(--windows-grey);
  margin       : 2px 1px 3px 1px;
`

const FooterLine = styled.div.attrs({
  className: 'Windows95Terminal-FooterLine'
})`
  margin    : 2px 1px 0px 1px;
  border-top: 1px solid var(--windows-grey);
`


function Summary({ url, language }) {
  const urlToComponent = {
    welcome: WelcomeSummary,
    'who-we-are': WhoWeAreSummary,
    'what-we-do': WhatWeDoSummary,
    'company-mission': CompanyMissionSummary,
  }

  const Component = urlToComponent[url]

  return <Component language={language} />
}

export default function Windows95Program({
  name,
  language,
  offsetForInitialRenderPosition = 0,
  isHidden,
  isMaximized,
  maximizeProgram = noop,
  minimizeProgram = noop,
  hideProgram = noop,
  onClose = noop,
  url,
  title,
  icon,
  children
}) {
  const terminalRef = useRef(null)
  const [isFocused, setIsFocused] = useState(true)

  const focusWindow = useCallback(() => {
    setIsFocused(true)
  }, [])

  const blurWindow = useCallback(() => {
    setIsFocused(false)
  }, [])

  useDraggable(terminalRef, { draggableElementSelector: '.Windows95ProgramHeader', disableDrag: isMaximized })
  useOutsideClick(terminalRef, blurWindow)

  useEffect(() => {
    if (isMaximized) {
      const header = terminalRef.current
      header.style.top = ''
      header.style.left = ''
    }
  }, [isMaximized])

  return (
    <Windows95TerminalWrapper
      ref={terminalRef}
      onMouseDown={focusWindow}
      offset={offsetForInitialRenderPosition}
      isHidden={isHidden}
      isFocused={isFocused}
      isMaximized={isMaximized}
    >
      <Windows95ProgramHeader
        url={title || url}
        icon={icon}
        isDragDisabled={isMaximized}
        onDoubleClick={isMaximized ? minimizeProgram : maximizeProgram}
        onMinimize={hideProgram}
        onMaximize={isMaximized ? minimizeProgram : maximizeProgram}
        onClose={onClose}
      />

      <Body isMaximized={isMaximized}>
        {children ? children : <Summary language={language} url={url} />}
      </Body>

      <Footer>
        <FooterLine />
        <FlexboxRow style={{ height: 'inherit' }}>
          <FooterSegment style={{ flex: '6' }} />
          <FooterSegment style={{ flex: '1' }} />
          <FooterSegment style={{ flex: '1' }} />
        </FlexboxRow>
      </Footer>
    </Windows95TerminalWrapper>
  )
}
