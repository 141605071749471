import React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import Statue from 'components/Statue'
import StatueGlitch from 'components/StatueGlitch'


const PositionedStatue = styled.div.attrs({
  className: 'PositionedStatue'
})`
  position: absolute;
  top     : var(--statue-top-offset-vh);
  left    : calc(50% - (var(--statue-width-px) / 2));
  z-index : var(--z-middle-layer-2);
  height  : var(--statue-height-px);
  width   : var(--statue-width-px);
`

export default function Desktop({
  onClickFolderIcon = noop
}) {
  return (
    <div>
      <StatueGlitch />
      <PositionedStatue>
        <Statue />
      </PositionedStatue>
    </div>
  )
}

