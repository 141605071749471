import React from 'react'
import noop from 'lodash/noop'
import styled, { css } from 'styled-components'

import FlexboxRow from 'components/FlexboxRow'

import minimize from 'assets/img/minimize-button.png'
import maximize from 'assets/img/maximize-button.png'
import close from 'assets/img/close-button.png'

const StyledWindows95ProgramHeader = styled.div.attrs({
  className: 'Windows95ProgramHeader'
})`
  font-family    : 'Microsoft';
  background     : #06157F;
  display        : flex;
  user-select    : none;
  flex-direction : row;
  justify-content: space-between;
  height         : var(--terminal-header-height);
  box-sizing     : border-box;
  cursor         : move;
  padding        : 2px;
  margin         : 3px 3px 1px 3px;

  ${({ isDragDisabled }) => isDragDisabled && css`
    cursor: default;
  `}
`

const ProgramIcon = styled.img.attrs({
  className: 'Windows95Terminal-ProgramIcon',
  alt: ''
})`
  height     : 15px;
  width      : 13px;
  margin-left: 3px;
`

const MinimizeButton = styled.img.attrs({
  className: 'Windows95Terminal-MinimizeButton',
  src: minimize,
  alt: ''
})`
  cursor     : pointer;
  flex-shrink: 0;
  min-width  : 18px;
`

const MaximizeButton = styled.img.attrs({
  className: 'Windows95Terminal-MaximizeButton',
  src: maximize,
  alt: ''
})`
  cursor     : pointer;
  margin     : 0px 2px;
  flex-shrink: 0;
  min-width  : 18px;
`

const CloseButton = styled.img.attrs({
  className: 'Windows95Terminal-CloseButton',
  src: close,
  alt: ''
})`
  cursor     : pointer;
  flex-shrink: 0;
  min-width  : 18px;
`

const CBackslash = styled.div.attrs({
  className: 'CBackslash'
})`
  color        : #fff;
  font-size    : 0.875rem;
  text-overflow: ellipsis;
  overflow     : hidden;
  flex-shrink  : 0;
  margin-left  : 0.3rem;
`

export function Windows95ProgramHeader({
  url,
  icon,
  onMinimize,
  onMaximize,
  isDragDisabled = false,
  showBackslash = true,
  onDoubleClick = noop,
  onClose = noop
}) {
  return (
    <StyledWindows95ProgramHeader
      isDragDisabled={isDragDisabled}
      onDoubleClick={onDoubleClick}
    >
      <FlexboxRow
        style={{
          alignItems: 'center',
          flex: 2,
          overflow: 'hidden'
        }}
      >
        {icon && <ProgramIcon src={icon} />}
        <CBackslash>
          C:/capalis.ch/{url}
        </CBackslash>
      </FlexboxRow>
      <FlexboxRow style={{ alignItems: 'center' }}>
        {onMinimize && (
          <MinimizeButton
            onMouseDown={onMinimize}
          />
        )}
        {onMaximize && (
          <MaximizeButton
            onMouseDown={onMaximize}
          />
        )}
        <CloseButton onMouseDown={onClose} />
      </FlexboxRow>
    </StyledWindows95ProgramHeader>
  )
}
