import styled from 'styled-components'

import BinaryGrid from 'assets/img/binary-grid.svg'

export default styled.img.attrs({
  src: BinaryGrid,
  alt: "binary number grid pattern"
})`
  position: absolute;
  bottom: 0;
  width: 100vw;
`
