import React from 'react'
import styled from 'styled-components'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Home from 'containers/Home'

const Wrapper = styled.div.attrs({
  classNames: 'Wrapper'
})`
  --totalPageHeight: 100vh;
  --totalPageWidth: 100vw;

  --windows-tan: #bdbebd;
  --windows-grey: #6D6D6D;

  --background-yellow: #FFDE00;

  --neon-red: #FF0000;
  --neon-yellow: #F9FF00;
  --neon-green: #56D7E4;

  --statue-width-px: 425px;
  --statue-height-px: 600px;
  --statue-top-offset-vh: 5vh;

  --column-overlap-statue-px: 35px;

  --folder-width-px: 70px;
  --folder-height-px: 60px;

  --z-background: 0;
  --z-middle-layer-1: 1;
  --z-middle-layer-2: 2;
  --z-foreground: 3;
  --z-foreground-focused: 4;
  --z-navbar-layer: 5;
  --z-overlay: 6;

  /* TERMINAL/PROGRAM STYLES */
  --terminal-width-px: 400px;
  --terminal-height-px: 350px;
  --initial-top-offset-vh: 48vh;
  --initial-right-offset-px: 30px;

  --terminal-header-height: 20px;
  --terminal-footer-height: 20px;
  --terminal-min-width-px: 200px;

  /* WINDOWS 95 MENU STYLES */
  --open-program-tab-width-px: 200px;

  /* CLOUDS */
  --first-clouds-offset-vh: 0vh;
  --second-clouds-offset-vh: 5vh;
  --small-cloud-width-px: 1000px;
  --large-cloud-width-px: 1200px;
  --cloud-bottom-spread-multiplier-px: 300px;
  --cloud-top-spread-multiplier-px: 0px;

  /* GLITCH STYLES */
  --glitch-width-px: 308px;
  --glitch-height-px: 785px;
  --glitch-left-offset: 30vw;
  --glitch-top-offset: 10vh;
  --gap-horizontal: 50px;
	--gap-vertical: 10px;
  --time-anim: 6s;
	--delay-anim: 3s;

  min-height: 100vh;
  height    : 100vh;
  width     : var(--totalPageWidth);
  overflow  : hidden;

  --navbar-height: 40px;

  background: var(--background-yellow);

  /*
    Target resolutions:
      DESKTOP
      - >= 2560 x 1440
      - 1920x1080
      - 1440x900

      TABLET
      - 1280x800
      - 1024x768 (Tablet vertical) IPAD
      - 768x1024 (Tablet horizontal)

      MOBILE
      - 393x767 Xiaomi Redmi 8 Pro
      - 320x480
      - 360x640
      - 375x667
  */

  @media screen and (min-width: 320px) {
    --terminal-width-px: 200px;
    --terminal-height-px: 200px;
    --initial-top-offset-vh: 44vh;
    --initial-right-offset-px: 20px;

    --monolith-width-px: 225px;

    --first-clouds-offset-vh: 18vh;
    --second-clouds-offset-vh: 20vh;
    --small-cloud-width-px: 150px;
    --large-cloud-width-px: 200px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --statue-width-px: 150px;
    --statue-height-px: 460px;
    --statue-top-offset-vh: 15vh;

    --glitch-top-offset: 15vh;
    --glitch-left-offset: 5vw;
    --glitch-width-px: 150px;
    --glitch-height-px: 460px;

    --open-program-tab-width-px: 50px;

    --folder-width-px: 55px;
    --folder-height-px: 50px;
  }

  @media screen and (min-width: 375px) {
    /* CSS for other than iOS devices */
    --terminal-width-px: 300px;
    --terminal-height-px: 300px;
    --initial-top-offset-vh: 30vh;
    --initial-right-offset-px: 30px;

    min-height: 100vh;
    height    : 100vh;

    --statue-width-px: 150px;
    --statue-height-px: 250px;
    --statue-top-offset-vh: 3vh;

    --first-clouds-offset-vh: 14vh;
    --second-clouds-offset-vh: 7vh;
    --small-cloud-width-px: 250px;
    --large-cloud-width-px: 300px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --open-program-tab-width-px: 100px;

  }

  /* iPhone XS and iPhone X */
  @media only screen and (min-width: 375px) and (-webkit-device-pixel-ratio: 3) {
    --first-clouds-offset-vh: 26vh;
    --second-clouds-offset-vh: 27vh;
    --small-cloud-width-px: 150px;
    --large-cloud-width-px: 225px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    /* The real height of the renderable pixels */
    /* min-height: 600px;
    height    : 600px; */

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 175px;
    --statue-height-px: 560px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 175px;
    --glitch-height-px: 560px;
    --glitch-left-offset: 15vw;

    --initial-top-offset-vh: 35vh;
    --initial-right-offset-px: 30px;

    .BottomCloudWrapper, .TopCloudWrapper {
      display: block;
    }
  }

  /* Pixel2 & Pixel2 XL */
  @media screen and (min-width: 411px) {
    --first-clouds-offset-vh: 24vh;
    --second-clouds-offset-vh: 24vh;
    --small-cloud-width-px: 200px;
    --large-cloud-width-px: 275px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 175px;
    --statue-height-px: 560px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 175px;
    --glitch-height-px: 560px;
    --glitch-left-offset: 15vw;

    --initial-top-offset-vh: 42vh;
    --initial-right-offset-px: 30px;

    .BottomCloudWrapper, .TopCloudWrapper {
      display: block;
    }
  }

  /* iphone 6, 6s, 7, 8 */
  @media only screen and (min-width: 375px) and (-webkit-device-pixel-ratio: 2) {
    --first-clouds-offset-vh: 22vh;
    --second-clouds-offset-vh: 20vh;

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 150px;
    --statue-height-px: 460px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 150px;
    --glitch-height-px: 460px;
    --glitch-left-offset: 15vw;
  }


  /* Xiaomi Redmi 6 pro */
  @media screen and (min-width: 393px) {
    --first-clouds-offset-vh: 24vh;
    --second-clouds-offset-vh: 24vh;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --statue-top-offset-vh: 17vh;
    --statue-width-px: 210px;
    --statue-height-px: 660px;

    --glitch-top-offset: 15vh;
    --glitch-width-px: 210px;
    --glitch-height-px: 660px;
    --glitch-left-offset: 10vw;

    --initial-top-offset-vh: 52vh;
    --initial-right-offset-px: 30px;
  }

  /* iphone 6+, 6s+, 7+, 8+ */
  @media only screen and (min-width: 414px) and (-webkit-device-pixel-ratio: 3) {
    --first-clouds-offset-vh: 20vh;
    --second-clouds-offset-vh: 20vh;;

    --small-cloud-width-px: 200px;
    --large-cloud-width-px: 275px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --initial-top-offset-vh: 36vh;
    --initial-right-offset-px: 30px;
    --statue-top-offset-vh: 30vh;
    --statue-width-px: 175px;
    --statue-height-px: 560px;

    --glitch-top-offset: 25vh;
    --glitch-width-px: 175px;
    --glitch-height-px: 560px;
    --glitch-left-offset: 15vw;

    .BottomCloudWrapper, .TopCloudWrapper {
      display: block;
    }
  }

  /* iPhone XR */
  @media only screen and (min-width : 414px) and (-webkit-device-pixel-ratio : 2) {
    --first-clouds-offset-vh: 20vh;
    --second-clouds-offset-vh: 20vh;

    --small-cloud-width-px: 200px;
    --large-cloud-width-px: 275px;

    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --initial-top-offset-vh: 36vh;
    --initial-right-offset-px: 30px;

    --initial-top-offset-vh: 36vh;
    --initial-right-offset-px: 30px;
    --statue-top-offset-vh: 30vh;
    --statue-width-px: 175px;
    --statue-height-px: 560px;

    --glitch-top-offset: 25vh;
    --glitch-width-px: 175px;
    --glitch-height-px: 560px;

    /* Real renderable pixels */
    min-height: 700px;
    height    : 700px;
  }

    /* iPhone XS Max */
  @media only screen and (min-width : 414px) and (-webkit-device-pixel-ratio : 3) {
    --first-clouds-offset-vh: 20vh;
    --second-clouds-offset-vh: 20vh;

    --small-cloud-width-px: 200px;
    --large-cloud-width-px: 275px;

    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --initial-top-offset-vh: 36vh;
    --initial-right-offset-px: 30px;
    --statue-top-offset-vh: 30vh;
    --statue-width-px: 175px;
    --statue-height-px: 560px;

    --glitch-top-offset: 25vh;
    --glitch-width-px: 175px;
    --glitch-height-px: 560px;
    --glitch-left-offset: 15vw;

    /* Real renderable pixels */
    min-height: 700px;
    height    : 700px;

  }

  @media screen and (min-width: 768px) {
    --terminal-width-px: 350px;
    --terminal-height-px: 320px;
    --initial-top-offset-vh: 38vh;
    --initial-right-offset-px: 30px;

    min-height: 100vh;
    height    : 100vh;

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 230px;
    --statue-height-px: 660px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 230px;
    --glitch-height-px: 660px;
    --glitch-left-offset: 20vw;

    --first-clouds-offset-vh: 22vh;
    --second-clouds-offset-vh: 16vh;
    --small-cloud-width-px: 450px;
    --large-cloud-width-px: 500px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 0px;

    --open-program-tab-width-px: 150px;

    --folder-width-px: 70px;
    --folder-height-px: 60px;

    .BottomCloudWrapper, .TopCloudWrapper {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    --terminal-width-px: 350px;
    --terminal-height-px: 350px;
    --initial-top-offset-vh: 35vh;
    --initial-right-offset-px: 50px;

    --statue-top-offset-vh: 20vh;
    --statue-width-px: 230px;
    --statue-height-px: 660px;

    --glitch-top-offset: 15vh;
    --glitch-width-px: 230px;
    --glitch-height-px: 660px;
    --glitch-left-offset: 27vw;

    --first-clouds-offset-vh: 5vh;
    --second-clouds-offset-vh: 11vh;
    --small-cloud-width-px: 450px;
    --large-cloud-width-px: 550px;
    --cloud-bottom-spread-multiplier-px: 50px;
    --cloud-top-spread-multiplier-px: 10px;

  }

  /* iPad Pro Vertical */
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    --terminal-width-px: 500px;
    --terminal-height-px: 400px;
    --initial-top-offset-vh: 54vh;
    --initial-right-offset-px: 60px;

    --statue-top-offset-vh: 15vh;
    --statue-width-px: 420px;
    --statue-height-px: 1200px;

    --glitch-top-offset: 12vh;
    --glitch-width-px: 420px;
    --glitch-height-px: 1200px;
    --glitch-left-offset: 15vw;

    --first-clouds-offset-vh: 9vh;
    --second-clouds-offset-vh: 14vh;
    --small-cloud-width-px: 400px;
    --large-cloud-width-px: 700px;
    --cloud-bottom-spread-multiplier-px: 150px;
    --cloud-top-spread-multiplier-px: 25px;
  }


  @media screen and (min-width: 1280px) {
    --terminal-width-px: 450px;
    --terminal-height-px: 350px;
    --initial-top-offset-vh: 30vh;
    --initial-right-offset-px: 50px;

    --statue-top-offset-vh: 20vh;
    --statue-width-px: 210px;
    --statue-height-px: 660px;

    --glitch-top-offset: 15vh;
    --glitch-width-px: 210px;
    --glitch-height-px: 660px;
    --glitch-left-offset: 35vw;

    --first-clouds-offset-vh: 9vh;
    --second-clouds-offset-vh: 8vh;
    --small-cloud-width-px: 450px;
    --large-cloud-width-px: 600px;
    --cloud-bottom-spread-multiplier-px: 150px;
    --cloud-top-spread-multiplier-px: 0px;
  }

  @media screen and (min-width: 1440px) {
    --terminal-width-px: 500px;
    --terminal-height-px: 400px;
    --initial-top-offset-vh: 35vh;
    --initial-right-offset-px: 100px;

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 230px;
    --statue-height-px: 660px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 230px;
    --glitch-height-px: 660px;
    --glitch-left-offset: 35vw;

    --first-clouds-offset-vh: 7vh;
    --second-clouds-offset-vh: 12vh;
    --small-cloud-width-px: 500px;
    --large-cloud-width-px: 700px;
    --cloud-bottom-spread-multiplier-px: 200px;
    --cloud-top-spread-multiplier-px: 25px;

    --folder-width-px: 100px;
    --folder-height-px: 90px;
  }

  @media screen and (min-width: 1920px) {
    --terminal-width-px: 600px;
    --terminal-height-px: 500px;
    --initial-top-offset-vh: 35vh;
    --initial-right-offset-px: 150px;

    --statue-top-offset-vh: 25vh;
    --statue-width-px: 300px;
    --statue-height-px: 900px;

    --glitch-top-offset: 20vh;
    --glitch-width-px: 300px;
    --glitch-height-px: 900px;
    --glitch-left-offset: 35vw;

    --first-clouds-offset-vh: 7vh;
    --second-clouds-offset-vh: 8vh;
    --small-cloud-width-px: 700px;
    --large-cloud-width-px: 900px;
    --cloud-bottom-spread-multiplier-px: 300px;

    --open-program-tab-width-px: 200px;
  }

  @media screen and (min-width: 2560px) {
    --terminal-width-px: 800px;
    --terminal-height-px: 500px;
    --initial-top-offset-vh: 40vh;
    --initial-right-offset-px: 200px;

    --statue-top-offset-vh: 15vh;
    --statue-width-px: 420px;
    --statue-height-px: 1200px;

    --glitch-top-offset: 12vh;
    --glitch-width-px: 420px;
    --glitch-height-px: 1200px;
    --glitch-left-offset: 35vw;

    --open-program-tab-width-px: 200px;

    --first-clouds-offset-vh: 5vh;
    --second-clouds-offset-vh: 5vh;
    --small-cloud-width-px: 1400px;
    --large-cloud-width-px: 1800px;
    --cloud-bottom-spread-multiplier-px: 200px;
  }
`

function App() {
  return (
    <Router>
      <Wrapper className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/blog/:post" component={Home} />
          <Route path="/:program" component={Home} />
        </Switch>
      </Wrapper>
    </Router>
  )
}

export default App
