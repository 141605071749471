/* global google */
import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import GoogleLogo from "../assets/img/google-logo.svg"
import Star from "../assets/img/star.svg"
import StarEmpty from "../assets/img/star-empty.svg"

const GMAPS_URL = "https://www.google.com/maps/place/avaneq+GmbH/@47.3668987,8.518241,17z/data=!3m1!4b1!4m5!3m4!1s0x479abb2b7c8392fd:0xca21770650dcb29!8m2!3d47.3668951!4d8.5204297"

const StyledGoogleReviews = styled.div.attrs({
  className: "GoogleReviews"
})`
  /* We make an invisible div for the Google Maps API to link into. We don't want it messing with the website flow, but we need it on the page so that the pages details request will complete successfully. */
  #hidden-map-do-not-use {
    display: none;
    width: 0px;
    height: 0px;
  }

  position: absolute;
  bottom: var(--navbar-height);
  right: 15px;
  padding: 19px 13px;
  box-sizing: border-box;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  background: var(--windows-tan);

  img {
    margin-left: 13px;
    margin-bottom: 8px;
  }

  .GoogleReviews-name {
    font-family: Arial Narrow;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8px;
  }

  .GoogleReviews-star-count {
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-top: -2px;
      color: #E2671F;
    }
  }

  .GoogleReviews-review-count {
    text-align: center;
    font-family: Arial Narrow;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    margin-bottom: 8px;
  }
`

const StyledLinkButton = styled.a.attrs({
  className: "GoogleReviews-button",
  target: '_blank',
  rel: 'noreferrer noopener',
  href: GMAPS_URL
})`
  display: inline-block;
  width: 100%;
  height: 33px;
  text-align: center;
  color    : black;
  text-decoration: none;
  background-color: #CBCBCB;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  font-size: 16px;
  font-family: Arial;

  cursor: pointer;

  .GoogleReviews-button--outer {
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid rgb(146, 146, 146);
    border-right: 1px solid rgb(146, 146, 146);
    padding: 4px;
  }

  .GoogleReviews-button--inner {
    box-sizing: border-box;
    padding-top: 2px;
    border: 1px dotted black;
    height: 23px;
  }
`

function LinkButton({ children }) {
  return (
    <StyledLinkButton>
      <div className="GoogleReviews-button--outer">
        <div className="GoogleReviews-button--inner">
          {children}
        </div>
      </div>
    </StyledLinkButton>
  )
}

function ReviewStar({ isFilled }) {
  if (isFilled) {
    return <img src={Star} alt="star" />
  } else {
    return <img src={StarEmpty} alt="empty star" />
  }
}

export default function GoogleReviews({
  language,
  onClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop
}) {
  /**
   * We set the defaults to sensible (real) values so that if the API
   * fails we still have reasonable data. It also makes loading feel less jarring.
   */
  const [averageReview, setAverageReview] = useState(5)
  const [reviewCount, setReviewCount] = useState(32)

  useEffect(() => {
    function initMap() {
      // We have to instantiate an invisible map so we can access the places service.
      const map = new google.maps.Map(document.getElementById("hidden-map-do-not-use"), {
        center: { lat: -33.866, lng: 151.196 },
        zoom: 15
      });

      const request = {
        placeId: "ChIJ_ZKDfCu7mkcRKcsNZXAXogw",
        fields: ["name", "rating", "user_ratings_total"]
      };

      const service = new google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setReviewCount(place.user_ratings_total)
          setAverageReview(place.rating)
        }
      });
    }
    initMap()
  }, [])

  const generatedStars = useMemo(() => {
    // Always "round up" the average review value ;-)
    const starCount = Math.ceil(averageReview)
    const stars = []
    for (let i = 0; i < 5; i++) {
      if (i <= (starCount - 1)) {
        stars.push(<ReviewStar key={i} isFilled />)
      } else {
        stars.push(<ReviewStar key={i} />)
      }
    }
    return stars
  }, [averageReview])

  return (
    <StyledGoogleReviews
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div id="hidden-map-do-not-use" />

      <img src={GoogleLogo} alt="google logo" />
      <div className="GoogleReviews-name">capalis GmbH</div>
      <div className="GoogleReviews-star-count">
        <span>{averageReview}</span>
        {generatedStars}
      </div>
      <div className="GoogleReviews-review-count">
        {reviewCount} {language === "DE" ? "Bewertungen" : "Reviews"}
      </div>
      <LinkButton>{language === "DE" ? "alle Bewertungen lesen" : "Read all reviews"}</LinkButton>
    </StyledGoogleReviews>
  );
}
