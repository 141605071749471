import React, { useEffect } from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import FullscreenImg from 'assets/img/fullscreen.svg'
import ExitFullscreenImg from 'assets/img/exit_fullscreen.svg'

const StyledFullscreenToggle = styled.div.attrs({
  className: "FullscreenToggle"
})`
  padding-top: 2px;
  padding-right: 5px;
  opacity: 0.7;

  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
  }
`

/* View in fullscreen */
function openFullscreen() {
  const elem = document.documentElement

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

/* Close fullscreen */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
}

export default function FullscreenToggle({
  isFullscreen,
  onClick = noop
}) {

  useEffect(() => {
    if (!document.fullscreenElement && isFullscreen) openFullscreen();
    else if (document.fullscreenElement && !isFullscreen) closeFullscreen()
  }, [isFullscreen])

  return (
    <StyledFullscreenToggle onClick={onClick}>
      {isFullscreen ? (
        <img alt="exit fullscreen icon" src={ExitFullscreenImg} />
      ) : (
        <img alt="fullscreen icon" src={FullscreenImg} />
      )}
    </StyledFullscreenToggle>
  );
}
