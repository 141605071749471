import React from 'react'
import styled from 'styled-components'

export const Linked = styled.a.attrs({
  className: 'ExternalLink',
  target: '_blank',
  rel: 'noreferrer noopener'
})`
  font-size: 1rem;
  color    : black;

  opacity  : 1;

  :hover {
    transition: opacity 100ms linear;
    opacity: 1;
  }
`

function ExternalLink(props) {
  return (
    <div style={{ margin: '4px 0px', display: 'inline-block' }}>
      <Linked {...props} />
    </div>
  )
}

export default ExternalLink
