import React from 'react'
import styled from 'styled-components'

import { flash } from 'animations'

const FlashingCursor = styled.span`
  height          : 1rem;
  line-height     : 1rem;
  border-left     : 1px solid ${props => props.color};
  border-right    : 1px solid ${props => props.color};
  background-color: transparent;
  margin          : 0 -2px;
  animation       : ${flash} ${props => props.flashSpeedMs}ms steps(1) infinite;
`

export default function({
  color = 'var(--neon-red)',
  flashSpeedMs = 1000,
  classNames,
  style = {},
}) {
  return (
    <FlashingCursor
      color={color}
      flashSpeedMs={flashSpeedMs}
      classNames={classNames}
      style={style}
    />
  )
}
