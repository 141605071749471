import React from 'react'

import noop from 'lodash/noop'
import styled from 'styled-components'

import TooltipImage from "assets/img/tooltip_bubble.svg"
import TooltipCloseIcon from "assets/img/tooltip_close_button.svg"

const StyledWindows95ToolTip = styled.div.attrs({
  className: "StyledWindows95ToolTip",
})`
  font-size: 1.2rem;
  border-radius: 1rem;
  position: absolute;
  white-space: nowrap;
  right: 97px;
  bottom: calc(var(--navbar-height) - 5px);

  div {
    position: absolute;
    top: 6px;
    left: 15px;
  }

  .ToolTip-close {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 18px;
  }

  @media only screen and (max-width: 375px) {
    display: none;
  }

`;

export default function Windows95ToolTip({
  message,
  onClose = noop
}) {
  return (
    <StyledWindows95ToolTip>
      <img className="ToolTip-bg" src={TooltipImage} alt="windows 95 tooltip" />
      <div>{message}</div>
      <img onClick={onClose} className="ToolTip-close" src={TooltipCloseIcon} alt="windows 95 x icon" />
    </StyledWindows95ToolTip>
  );
}
