import React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import AntivirusImg from 'assets/img/antivirus.png'

const StyledAntivirus = styled.div.attrs({
  className: "Antivirus"
})`
  padding-top: 2px;
  padding-right: 5px;

  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
  }
`

export default function Antivirus({
  onClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop
}) {
  return (
    <StyledAntivirus
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <img alt="antivirus icon" src={AntivirusImg} />
    </StyledAntivirus>
  );
}
