import React from "react"

import styled from 'styled-components'

import useMediaQuery from "hooks/useMediaQuery"

import bigGlitch from 'assets/img/avaneq-glitch-big.png'
import smallGlitch from 'assets/img/avaneq-glitch-small.png'

const StyledGlitch = styled.img.attrs({
  alt: "flickering glitch behind statue"
})`
  position      : absolute;
  left          : var(--glitch-left-offset);
  top           : var(--glitch-top-offset);
  user-select   : none;
  pointer-events: none;

  height: var(--glitch-height-px);
  width: var(--glitch-width-px);

  @keyframes flicker {
    0% {
      opacity:0.1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    .4% {
      opacity:0.1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    .8% {
      opacity:1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }


    3.8% {
      opacity:1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    4.2% {
      opacity:0.1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    4.6% {
      opacity:1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }

    16% {
      opacity:1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    16.6% {
      opacity:0.4;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
    17.4% {
      opacity:1;
      text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
    }
  }

  animation: flicker 5s infinite;
  animation-timing-function: linear;
`

export default function StatueGlitch() {
  const imgSrc = useMediaQuery(['(max-width: 1280px)', '(min-width: 1280px)'], [smallGlitch, bigGlitch], smallGlitch);

  return <StyledGlitch src={imgSrc} />
}
