import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FlashingCursor from 'components/FlashingCursor'
import FlexboxRow from 'components/FlexboxRow'

const Wrapper = styled.div.attrs({
  className: 'Wrapper'
})`
  user-select  : text;
  color        : var(--neon-green);

  h1 {
    font-size: 3rem;
    line-height: 3rem;
    padding: 0px 5px;
    margin: 0;
    /* Offset for red highlight so text aligns on left */
    margin-left: -8px;
    margin-bottom: 0.75rem;

    :after {
      display: block;
      font-size: 1.125rem;
      margin-top: 0.75rem;
      color: #3F3F3F;
      content: '////////////////';
    }
  }

  h2 {
    font-size: 2.25rem;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  a {
    transition: all 100ms;
    color: var(--neon-yellow);
    :hover {
      color: var(--neon-red);
    }
  }

  img:not(.FolderIcon), iframe, video {
    display: block;
    max-width: 100%;
    /* center images inside p tags by default */
    margin: 16px auto;
  }

  p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }

  blockquote {
    background: var(--neon-yellow);
    color: #000;
    padding: 4px 10px;
    font-size: 1.4rem;

    a {
      color: #000;
    }

    p {
      margin: 0;
    }
  }

  strong {
    color: var(--neon-red);
  }

  code {
    white-space: break-spaces;
  }
`

const Date = styled.div`
  color: #6E6E6E;
  text-decoration: underline;
  font-size: 0.875rem;
  font-weight: bold;
`

const NavTitle = styled.div`
  font-size: 0.875rem;
  margin-top: 4px;
  color: #6E6E6E;
`

export default function ContentPage({
  tags = [],
  date,
  next,
  previous,
  children
}) {
  return (
    <Wrapper>
      {children}
      <FlashingCursor />

      <Date>{date}</Date>

      <FlexboxRow
        isCentered
        style={{ justifyContent: 'space-between' }}
      >
        <div style={{ margin: '16px 0', fontSize: '0.875rem' }}>
          {previous && (
            <div>
              <Link to={`/blog/${previous.articleName}`}>
                {`<< Previous`}
              </Link>
              <NavTitle>{previous.title}</NavTitle>
            </div>
          )}
        </div>
        <div style={{ margin: '8px 0', fontSize: '0.875rem' }}>
          {next && (
            <div style={{ textAlign: 'end' }}>
              <Link to={`/blog/${next.articleName}`}>
                {`Next >>`}
              </Link>
              <NavTitle>{next.title}</NavTitle>
            </div>
          )}
        </div>
      </FlexboxRow>
    </Wrapper>
  )
}
