import React from 'react'
import styled from 'styled-components'

import useMediaQuery from "hooks/useMediaQuery"

import bigStatue from 'assets/img/avaneq-statue-big.png'
import smallStatue from 'assets/img/avaneq-statue-small.png'

const StatueImg = styled.img.attrs({
  className: 'StatueImg',
  alt: 'alan turing seated at enigma machine'
})`
  position: absolute;
  height  : 100%;
  width   : 100%;

  pointer-events: none;
`

const StatueWrapper = styled.div.attrs({
  className: 'StatueWrapper'
})`
  position      : relative;
  width         : var(--statue-width-px);
  height        : var(--statue-height-px);
  z-index       : var(--z-middle-layer-2);
  overflow      : hidden;
  margin        : 0 auto;
  user-select   : none;
  pointer-events: none;
`

export default function Statue() {
  const imgSrc = useMediaQuery(['(max-width: 1280px)', '(min-width: 1280px)'], [smallStatue, bigStatue], smallStatue);

  return (
    <StatueWrapper>
      <StatueImg src={imgSrc} />
    </StatueWrapper>
  )
}
