import React, {
  useState,
  useRef,
  useEffect,
  useContext
} from 'react'
import styled, { css } from 'styled-components'
import noop from 'lodash/noop'

import LanguageContext from "LanguageContext"

import { Windows95ProgramHeader } from 'components/Windows95Program/Elements'
import FlexboxRow from 'components/FlexboxRow'
import ExternalLink from 'components/ExternalLink'

import BEWILLIGUNGEN_PDF from "assets/pdf/Bewilligungen.pdf"
import DATENSCHUTZ_PDF from "assets/pdf/Datenschutz.pdf"

import useDraggable from 'hooks/useDraggable'

const SystemMenuWrapper = styled.div.attrs({
  className: 'SystemMenuWrapper'
})`
  font-family: 'Microsoft';
  position: absolute;
  top: 100px;
  left: 100px;

  h2 {
    margin-top: 0;
  }

  background: var(--windows-tan);

  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  z-index: var(--z-navbar-layer);

  width: 410px;
  height: 500px;

  @media screen and (min-width: 320px) {
    top: 0px;
    left: 0px;
    width: 320px;
  }

  @media screen and (min-width: 375px) {
    left: 0px;
    width: 375px;
  }
`

const SubMenu = styled.div.attrs({
  className: 'SubMenu'
})`
  height: 420px;
`

const SubMenuTabs = styled.div.attrs({
  className: 'SubMenuTabs'
})`
  height: 21px;
  z-index: 1;
  user-select: none;
`

const SubMenuTab = styled.span.attrs({
  className: 'SubMenuTab'
})`
  cursor       : pointer;
  display      : inline-block;
  height       : 17px;
  font-size    : 0.85rem;
  padding      : 3px 12px 0px 12px;
  background   : var(--windows-tan);
  border-left  : 1px solid white;
  border-bottom: 1px solid white;
  border-top   : 1px solid white;
  border-right : 1px solid black;

  ${({ isSelected }) => isSelected && css`
    border-bottom: none;
    height       : 19px;
    border-left  : 2px solid white;
    transform    : translateY(-1px);
    border-radius: 2px 2px 0 0;

    :first-of-type {
      border-left: 1px solid white;
    }
  `}
`

const SubMenuBody = styled.div.attrs({
  className: 'SubMenuBody'
})`
  border-left  : 1px solid white;
  border-top   : 1px solid white;
  border-bottom: 1px solid black;
  border-right : 1px solid black;
  z-index      : 0;
  padding      : 20px;
  height       : 350px;
`

const Bottom = styled.div`
  padding: 10px;
  padding-bottom: 30px;
  height: 410px;
`

const CloseButton = styled.button.attrs({
  className: 'CloseButton'
})`
  font-family: 'Microsoft';
  cursor: pointer;
  display: inline-block;
  width: 75px;
  height: 23px;
  float: right;

  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  margin-top: 5px;

  background: none;
`

const SystemMenuContent = styled.div`
  width      : 100%;
  padding    : 20px;
  line-height: 1.5;

  h2 {
    font-size    : 1.5rem;
    font-weight  : 400;
    margin-bottom: 8px;
  }
`

function AboutUs({ language }) {
  if (language === "DE") {
    return (
      <FlexboxRow>
        <SystemMenuContent>
          <h2>
            Kontakt
          </h2>
          <div>
            Gerne können Sie über die
            folgenden Kanäle mit uns in
            Verbindung treten.
            <br/>
            <br />
            E-Mail: hello@capalis.ch
            <br />
            Whatsapp: +41 76 213 54 95
            <br />
            Telefon: +41 76 213 54 95

            <hr />
            Capalis Consulting GmbH
            <br />
            Boulevard Lilienthal 3
            <br />
            8152 Opfikon
            <br />
            <br />

            <ExternalLink href="https://www.google.ch/maps/place/Capalis+Consulting+GmbH/@47.4246261,8.5605134,17z/data=!3m1!4b1!4m5!3m4!1s0x479abb2b7c8392fd:0xca21770650dcb29!8m2!3d47.4246225!4d8.5627021">Auf Google Maps anzeigen lassen</ExternalLink>

          </div>
        </SystemMenuContent>
      </FlexboxRow>
    )
  } else {
    return (
      <FlexboxRow>
        <SystemMenuContent>
          <h2>
            Contact
          </h2>
          <div>
            You are welcome to contact us through the following channels.
            <br />
            <br />
            E-Mail: hello@capalis.ch
            <br />
            Whatsapp: +41 76 213 54 95
            <br />
            Phone: +41 76 213 54 95

            <hr />
            Capalis Consulting GmbH
            <br />
            Boulevard Lilienthal 3
            <br />
            8152 Opfikon
            <br />
            <br />

            <ExternalLink href="https://www.google.ch/maps/place/Capalis+Consulting+GmbH/@47.4246261,8.5605134,17z/data=!3m1!4b1!4m5!3m4!1s0x479abb2b7c8392fd:0xca21770650dcb29!8m2!3d47.4246225!4d8.5627021">Google Maps</ExternalLink>

          </div>
        </SystemMenuContent>
      </FlexboxRow>
    )
  }
}

function Legal({ language }) {

  if (language === "DE") {
    return (
      <FlexboxRow>
        <SystemMenuContent>
          <h2>Capalis Consulting GmbH</h2>
          <div>
            Copyright &copy; 2020
          </div>
          <br />
          <div>
            <ExternalLink href={BEWILLIGUNGEN_PDF}>Bewilligungen</ExternalLink>
            <br />
            <ExternalLink href={DATENSCHUTZ_PDF}>Datenschutz</ExternalLink>
          </div>
          <br />
          <hr />
          <div>
            CHE-497.772.858
          </div>
        </SystemMenuContent>
      </FlexboxRow>
    )
  } else {
    return (
      <FlexboxRow>
        <SystemMenuContent>
          <h2>Capalis Consulting GmbH</h2>
          <div>
            Copyright &copy; 2020
          </div>
          <br />
          <div>
            <ExternalLink href={BEWILLIGUNGEN_PDF}>Company Incorporation Document</ExternalLink>
            <br />
            <ExternalLink href={DATENSCHUTZ_PDF}>Privacy Policy</ExternalLink>
          </div>
          <br />
          <hr />
          <div>
            CHE-497.772.858
          </div>
        </SystemMenuContent>
      </FlexboxRow>
    )
  }
}

export default function SystemMenu({
  defaultTab = 0,
  onClose = noop,
}) {
  const { language } = useContext(LanguageContext)
  const [selectedTab, setSelectedTab] = useState(defaultTab)
  const menuRef = useRef(null)

  useEffect(() => {
    // Resets the selected tab if user toggles the menu open from elsewhere in the app
    setSelectedTab(defaultTab)
  }, [defaultTab])

  useDraggable(menuRef, {
    draggableElementSelector: '.Windows95ProgramHeader'
  })

  function selectTab(tabNum) {
    setSelectedTab(tabNum)
  }

  return (
    <SystemMenuWrapper ref={menuRef}>
      <Windows95ProgramHeader
        className="SystemMenuHeader"
        url="capalis.ch"
        showBackslash={false}
        onClose={onClose}
      />
      <Bottom>
        <SubMenu>
          <SubMenuTabs>
            <SubMenuTab
              onClick={() => selectTab(0)}
              isSelected={selectedTab === 0}
            >
              About
            </SubMenuTab>
            <SubMenuTab
              onClick={() => selectTab(1)}
              isSelected={selectedTab === 1}
            >
              Legal
            </SubMenuTab>
          </SubMenuTabs>
          <SubMenuBody>
            {selectedTab === 0 && <AboutUs language={language} />}
            {selectedTab === 1 && <Legal language={language} />}
          </SubMenuBody>
        </SubMenu>

        <CloseButton onClick={onClose}>OK</CloseButton>

      </Bottom>
    </SystemMenuWrapper>
  )
}
