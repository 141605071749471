import React from 'react'
import styled, { css } from 'styled-components'

import {
  float,
  glitchAnimOne,
  glitchAnimTwo,
  glitchAnimThree,
  glitchAnimFlash,
  randomIntFromInterval
} from 'animations'

import CloudOneImg from 'assets/img/clouds/cloud1-blue-min.png'
import CloudThreeImg from 'assets/img/clouds/cloud3-blue-min.png'
import CloudSixImg from 'assets/img/clouds/cloud6-blue-min.png'

// Common styling for all clouds
const Cloud = styled.img`
  position: absolute;
  animation: ${props => props.float} ${props => props.duration}s infinite;
  animation-timing-function: ease-in-out;

  ${({ glitch }) => glitch && css`
    opacity: 0;
    /* Reenable glitch here if desired. */
    /* animation-duration: ${randomIntFromInterval(5, 12)}s;
    animation-delay: ${randomIntFromInterval(5, 15)}s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: ${glitch}; */

    ${({ hue, saturation }) => hue && css`
      filter: ${`hue-rotate(${hue}deg)`} ${`saturate(${saturation})`};
      -webkit-filter: ${`hue-rotate(${hue}deg)`} ${`saturate(${saturation})`};
    `}
  `}
`

const CloudSevenAsset = styled(Cloud).attrs({
  className: 'CloudSeven',
  src: CloudOneImg,
  alt: 'floating pink cloud'
})`
  z-index: var(--z-foreground-focused);
  width: var(--small-cloud-width-px);
  /* height: 37vh; */
  top: 63vh;
  /* left: 24vw; */
  left: calc(50vw - (var(--small-cloud-width-px) / 2) - var(--cloud-bottom-spread-multiplier-px));
`

function CloudSeven({ float, duration, hue, saturation }) {

  return (
    <React.Fragment>
      <CloudSevenAsset float={float} duration={duration} />
      <CloudSevenAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimOne} />
      <CloudSevenAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimTwo} />
      <CloudSevenAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimThree} />
      <CloudSevenAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimFlash} />
    </React.Fragment>
  )
}

const CloudNineAsset = styled(Cloud).attrs({
  className: 'CloudNine',
  src: CloudThreeImg,
  alt: 'floating pink cloud'
})`
  /* SPECIAL case to massage cloud positioning */
  --cloud-nine-offset-px: calc(var(--large-cloud-width-px) / 4);

  z-index: var(--z-foreground-focused);
  width: var(--large-cloud-width-px);
  /* height: 39.6vh; */
  top: 50vh;
  /* left: 40vw; */
  left: calc(50vw - (var(--large-cloud-width-px) / 2) - var(--cloud-bottom-spread-multiplier-px) + var(--cloud-nine-offset-px));
`

function CloudNine({ float, duration, hue, saturation }) {

  return (
    <React.Fragment>
      <CloudNineAsset float={float} duration={duration} />
      <CloudNineAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimOne} />
      <CloudNineAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimTwo} />
      <CloudNineAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimThree} />
      <CloudNineAsset hue={hue} saturation={saturation} duration={duration} glitch={glitchAnimFlash} />
    </React.Fragment>
  )
}


const CloudTwelveAsset = styled(Cloud).attrs({
  className: 'CloudTwelve',
  src: CloudSixImg,
  alt: 'floating pink cloud'
})`
  z-index: var(--z-middle-layer-1);
  width  : var(--large-cloud-width-px);
  /* height : 39.6vh; */
  top    : 67vh;
  /* left   : 48vw; */
  right: calc(-50vw - (var(--large-cloud-width-px) / 2) - var(--cloud-bottom-spread-multiplier-px));
`

function CloudTwelve({ float, duration, hue, saturation }) {
  return (
    <React.Fragment>
      <CloudTwelveAsset float={float} duration={duration} />
    </React.Fragment>
  )
}

const BottomCloudsWrapper = styled.div.attrs({
  className: 'BottomCloudWrapper'
})`
  position: absolute;
  top : var(--second-clouds-offset-vh);
  left: 0;
  /* left: calc(50vw - (var(--small-cloud-width-px) / 2)) */
  pointer-events: none;
`

function Clouds() {
  return (
    <React.Fragment>
      {/* Bottom Clouds */}
      <BottomCloudsWrapper>
        <CloudSeven
          duration={10}
          float={float(10, 30)}
          hue={randomIntFromInterval(45, 270)}
          saturation={randomIntFromInterval(1, 3)}
        />
        <CloudNine
          duration={13}
          float={float(10, 30)}
          hue={randomIntFromInterval(45, 270)}
          saturation={randomIntFromInterval(1, 3)}
        />
        <CloudTwelve
          duration={14}
          float={float(10, 30)}
          hue={randomIntFromInterval(45, 270)}
          saturation={randomIntFromInterval(1, 3)}
        />
      </BottomCloudsWrapper>
    </React.Fragment>
  )
}

export default React.memo(Clouds, (prevProps, nextProps) => {
  return true
})
