import styled from 'styled-components'

import CapalisLogo from 'assets/img/capalis-logo.svg'

export default styled.img.attrs({
  src: CapalisLogo,
  alt: 'capalis logo'
})`
  position: absolute;
  top: 3vh;
  right: 2vw;
  width: 300px;
  height: 71px;
`
