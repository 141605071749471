import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import BlogPost from "containers/BlogPost";

import Desktop from "components/Desktop";
import Clouds from "components/Clouds";
import Windows95Program from "components/Windows95Program";
import Windows95NavBar from "components/Windows95NavBar";
import SystemMenu from "components/SystemMenu";
import MountainGrid from "components/MountainGrid";
import BinaryGrid from "components/BinaryGrid";
import CapalisLogo from "components/CapalisLogo";

import BriefcaseIcon from "assets/img/windows-95/briefcase.ico";
import ComputerIcon from "assets/img/windows-95/pc.ico";
import UserIcon from "assets/img/windows-95/user.ico";
import CPromptIcon from "assets/img/windows-95/c-prompt.ico";
import NotepadIcon from "assets/img/windows-95/notepad.ico";

import LanguageContext from "../LanguageContext";

const StyledHome = styled.div.attrs({
  className: "Home",
})`
  position: relative;
  height: 100vh;

  /* Prevents huge clouds from overflowing */
  overflow: hidden;
`;

const PROGRAMS_EN = {
  Welcome: {
    name: "Welcome",
    url: "welcome",
    title: "Welcome",
    icon: ComputerIcon,
    alt: "computer icon windows 95",
    menuStyles: {
      backgroundSize: "auto 80%",
      backgroundPosition: "5px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
  "What We Do": {
    name: "What We Do",
    url: "what-we-do",
    title: "What We Do",
    icon: BriefcaseIcon,
    alt: "briefcase icon windows 95",
    menuStyles: {
      backgroundSize: "auto 65%",
      backgroundPosition: "8px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
  "Who We Are": {
    name: "Who We Are",
    url: "who-we-are",
    title: "Who We Are",
    icon: UserIcon,
    alt: "address book user icon windows 95",
    menuStyles: {},
    isHidden: false,
    isMaximized: false,
  },
  'Company Mission': {
    name: 'Company Mission',
    url: 'company-mission',
    title: 'Generation Alpha',
    icon: CPromptIcon,
    alt: "c prompt icon MS-DOS",
    menuStyles: {
      backgroundSize: "auto 65%",
      backgroundPosition: "9px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
};

const PROGRAMS_DE = {
  Welcome: {
    name: "Welcome",
    url: "welcome",
    title: "Willkommen",
    icon: ComputerIcon,
    alt: "computer icon windows 95",
    menuStyles: {
      backgroundSize: "auto 80%",
      backgroundPosition: "5px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
  "What We Do": {
    name: "What We Do",
    url: "what-we-do",
    title: "Was wir tun",
    icon: BriefcaseIcon,
    alt: "briefcase icon windows 95",
    menuStyles: {
      backgroundSize: "auto 65%",
      backgroundPosition: "8px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
  "Who We Are": {
    name: "Who We Are",
    url: "who-we-are",
    title: "Wer wir sind",
    icon: UserIcon,
    alt: "address book user icon windows 95",
    menuStyles: {},
    isHidden: false,
    isMaximized: false,
  },
  'Company Mission': {
    name: 'Company Mission',
    url: 'company-mission',
    title: "Generation Alpha",
    icon: CPromptIcon,
    alt: "c prompt icon MS-DOS",
    menuStyles: {
      backgroundSize: "auto 65%",
      backgroundPosition: "9px 50%",
    },
    isHidden: false,
    isMaximized: false,
  },
};

export default function Home({ match }) {
  const [language, setLanguage] = useState("DE");

  const PROGRAMS = useMemo(() => {
    if (language === "EN") return PROGRAMS_EN;
    else return PROGRAMS_DE;
  }, [language]);

  const ALL_PROGRAMS = useMemo(() => Object.values(PROGRAMS), [PROGRAMS]);

  const [openPrograms, setOpenPrograms] = useState([PROGRAMS.Welcome]);
  const [isSystemMenuOpen, setIsSystemMenuOpen] = useState(false);
  const [defaultSystemMenuTab, setDefaultSystemMenuTab] = useState(0);
  const [openBlogPost, setOpenBlogPost] = useState();

  const history = useHistory();

  const findProgramInList = useCallback(
    (programName) => {
      return openPrograms.find((program) => program.name === programName);
    },
    [openPrograms]
  );

  const toggleLanguage = useCallback(() => {
    if (language === "DE") {
      setLanguage("EN");
      setOpenPrograms(
        openPrograms.map((program) => ({
          ...program,
          title: PROGRAMS_EN[program.name].title,
        }))
      );
    } else {
      setLanguage("DE");
      setOpenPrograms(
        openPrograms.map((program) => ({
          ...program,
          title: PROGRAMS_DE[program.name].title,
        }))
      );
    }
  }, [language, openPrograms]);

  const openProgram = useCallback(
    (programName) => {
      if (findProgramInList(programName)) return;
      setOpenPrograms([...openPrograms, PROGRAMS[programName]]);
    },
    [openPrograms, setOpenPrograms, findProgramInList, PROGRAMS]
  );

  const minimizeProgram = useCallback(
    (programName) => {
      const index = openPrograms.findIndex(
        (program) => program.name === programName
      );

      const newProgram = Object.assign({}, openPrograms[index]);
      newProgram.isMaximized = false;

      const newPrograms = openPrograms.slice();
      newPrograms[index] = newProgram;

      setOpenPrograms(newPrograms);
    },
    [openPrograms]
  );

  const maximizeProgram = useCallback(
    (programName) => {
      const index = openPrograms.findIndex(
        (program) => program.name === programName
      );

      const newProgram = Object.assign({}, openPrograms[index]);
      newProgram.isMaximized = true;

      const newPrograms = openPrograms.slice();
      newPrograms[index] = newProgram;

      setOpenPrograms(newPrograms);
    },
    [openPrograms]
  );

  function showProgram(programName) {
    const index = openPrograms.findIndex(
      (program) => program.name === programName
    );

    // It's a blog post!
    if (index === -1) {
      setOpenBlogPost({
        ...openBlogPost,
        isHidden: false,
        isMaximized: true,
      });
    } else {
      const newProgram = Object.assign({}, openPrograms[index]);
      newProgram.isHidden = false;

      const newPrograms = openPrograms.slice();
      newPrograms[index] = newProgram;

      setOpenPrograms(newPrograms);
    }
  }

  function openSystemMenu(defaultTab) {
    setIsSystemMenuOpen(true);
    setDefaultSystemMenuTab(defaultTab);
  }

  useEffect(() => {
    function openProgramBasedOnCurrentUrl() {
      const { params } = match;

      if (params.program) {
        const programInUrl = _.capitalize(params.program);

        // ^^ handles who-we-are and similar multi word urls
        const program = PROGRAMS[programInUrl];

        if (program) {
          program.isMaximized = true;
          openProgram(programInUrl);
        }

        // this is a blog post, so open the blog post
      } else if (/blog\/[a-z]/.test(match.url) && match.params.post) {
        setOpenBlogPost({
          post: match.params.post,
          icon: NotepadIcon,
          name: match.params.post.split("-").map(_.capitalize).join(" "),
          isMaximized: true,
          isHidden: false,
        });
      }
    }

    openProgramBasedOnCurrentUrl();
  }, [match, openProgram, PROGRAMS]);

  const hideProgram = useCallback(
    (programName) => {
      const index = openPrograms.findIndex(
        (program) => program.name === programName
      );

      // It's a blog post!
      if (index === -1) {
        setOpenBlogPost({
          ...openBlogPost,
          isHidden: true,
        });
      } else {
        const newProgram = Object.assign({}, openPrograms[index]);
        newProgram.isHidden = true;

        const newPrograms = openPrograms.slice();
        newPrograms[index] = newProgram;

        setOpenPrograms(newPrograms);
      }
    },
    [openPrograms, setOpenPrograms, openBlogPost]
  );

  const memoizedPrograms = useMemo(() => {
    function closeProgram(programName) {
      let { params } = match;

      // If we close the window with the current url, get rid of that URL so we don't get into a weird state where we can't re-open the same window twice
      if (params.program === PROGRAMS[programName].url) {
        history.push("/");
      }

      setOpenPrograms(removeProgramFromList(programName));
    }

    function removeProgramFromList(programName) {
      const program = findProgramInList(programName);

      // reset url if it coincided with program to close
      if (program.isMaximized) {
        PROGRAMS[programName].isMaximized = false;
      }

      return openPrograms.filter((program) => program.name !== programName);
    }

    return openPrograms.map(
      ({ name, title, url, icon, isHidden, isMaximized }, i) => {
        return (
          <Windows95Program
            key={name}
            name={name}
            language={language}
            url={url}
            title={title}
            offsetForInitialRenderPosition={i}
            icon={icon}
            isMaximized={isMaximized}
            isHidden={isHidden}
            maximizeProgram={() => maximizeProgram(name)}
            hideProgram={() => hideProgram(name)}
            minimizeProgram={() => minimizeProgram(name)}
            onClose={() => closeProgram(name)}
          />
        );
      }
    );
  }, [
    openPrograms,
    minimizeProgram,
    maximizeProgram,
    hideProgram,
    findProgramInList,
    language,
    history,
    match,
    PROGRAMS,
  ]);

  const isAProgramMaximized = useMemo(() => {
    return openPrograms.some((program) => program.isMaximized);
  }, [openPrograms]);

  return (
    <StyledHome>
      <Helmet>
        <title>
          Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!
        </title>
        <meta
          property="og:title"
          content="Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!"
        />
        <meta property="og:url" content="https://www.capalis.ch" />
        <meta property="og:site_name" content="capalis.ch" />
        <meta
          name="description"
          content="Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!"
        />
      </Helmet>
      
      <h2 style={{ display: 'none' }}>Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!</h2>

      <BinaryGrid />
      <MountainGrid />

      <CapalisLogo />

      <Desktop onClickFolderIcon={openSystemMenu} />

      <LanguageContext.Provider value={{ language }}>
        {memoizedPrograms}
      </LanguageContext.Provider>

      {openBlogPost && (
        <BlogPost
          icon={openBlogPost.icon}
          maximizeProgram={() => {
            setOpenBlogPost({
              ...openBlogPost,
              isMaximized: true,
            });
          }}
          minimizeProgram={() => {
            setOpenBlogPost({
              ...openBlogPost,
              isMaximized: false,
            });
          }}
          hideProgram={() => {
            setOpenBlogPost({
              ...openBlogPost,
              isMaximized: false,
              isHidden: true,
            });
          }}
          onClose={() => {
            setOpenBlogPost();

            // If we close the window with the current url, get rid of that URL so we don't get into a weird state where we can't re-open the same window twice
            if (match.params.program === openBlogPost.articleName) {
              history.push("/");
            }
          }}
          isHidden={openBlogPost.isHidden}
          isMaximized={openBlogPost.isMaximized}
        />
      )}

      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        {isSystemMenuOpen && (
          <SystemMenu
            defaultTab={defaultSystemMenuTab}
            onClose={() => {
              setIsSystemMenuOpen(false);
              setDefaultSystemMenuTab(0);
            }}
          />
        )}

        <Windows95NavBar
          isOverlaid={isAProgramMaximized}
          onShowProgram={showProgram}
          onMinimizeProgram={hideProgram}
          onOpenProgram={openProgram}
          onOpenSystemMenu={openSystemMenu}
          programs={ALL_PROGRAMS}
          tabs={openPrograms
            .concat(openBlogPost ? [openBlogPost] : [])
            .map(({ isHidden, name, title, icon }) => {
              return { name, title, isHidden, icon };
            })}
        />
      </LanguageContext.Provider>

      <Clouds />
    </StyledHome>
  );
}
