import React from 'react'
import styled, { css } from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;

  ${({ isCentered }) => isCentered && css`
    align-items: center;
  `}
`

export default function FlexboxRow({ children, ...props }) {
  return (
    <Row {...props}>
      {children}
    </Row>
  )
}
