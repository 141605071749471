import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import _ from "lodash";
import noop from "lodash/noop";

import useOutsideClick from "hooks/useOutsideClick";

import { clamp } from "utils";
import LanguageContext from "LanguageContext";

import FlexboxRow from "components/FlexboxRow";
import FlexboxColumn from "components/FlexboxColumn";
import VolumeControlPanel from "components/VolumeControlPanel";
import EllipsisText from "components/EllipsisText";
import FullscreenToggle from "components/FullscreenToggle";
import Windows95ToolTip from "components/Windows95ToolTip";
import Antivirus from "components/Antivirus";
import Localization from "components/Localization";
import GoogleReviews from "components/GoogleReviews";

import WINDOWS_95_LOGO from "assets/img/windows-95/win95-logo.ico";
import VolumeOn from "assets/img/volume-on.png";
import VolumeOff from "assets/img/volume-off.png";
import AudioIcon from "assets/img/windows-95/audio.ico";
import OutlookExpressIcon from "assets/img/windows-95/outlook_express.ico";
import KeyLockIcon from "assets/img/windows-95/key_padlock.ico";

import SAPPHIRE_MP3 from "assets/sound/Sapphire_Sky.mp3";
import SAPPHIRE_WAV from "assets/sound/Sapphire_Sky.wav";

function getCurrentTime() {
  return moment().format("HH:mm A");
}

/**
 * With credit to https: //codepen.io/penry/pen/xEPKpj
 */
const Wrapper = styled.div.attrs({
  className: "Windows95NavBar-Wrapper",
})`
  font-family: "Microsoft";
  width: 100%;
  height: var(--navbar-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 2px solid #fff;
  padding: 3px;
  background: var(--windows-tan);
  z-index: var(--z-navbar-layer);
`;

const StartButton = styled.div`
  display: flex;
  cursor: default;
  user-select: none;
  flex-direction: row;
  align-items: center;
  color: black;
  min-width: 95px;
  width: 5.3vw;
  height: 100%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: right;
  padding: 0.2vh;
  padding-left: 4px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid black;
  border-bottom: 2px solid black;

  img {
    width: 1.8rem;
    margin-right: 4px;
  }

  ${({ isWindowsMenuOpen }) =>
    isWindowsMenuOpen &&
    css`
      border-top: 2px solid black;
      border-left: 2px solid black;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    `}
`;

const ClockWrapper = styled.div`
  cursor: default;
  width: 90px;
  font-size: 1.3rem;
  text-align: center;
  user-select: text;
  white-space: nowrap;
`;

function Clock() {
  const [time, setTime] = useState(getCurrentTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getCurrentTime());
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  return <ClockWrapper>{time}</ClockWrapper>;
}

const OpenProgramWrapper = styled.div`
  display: flex;
  cursor: default;
  user-select: none;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  height: 100%;
  width: var(--open-program-tab-width-px);
  font-size: 1.3rem;
  background: var(--windows-tan);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;

  div {
    margin-top: -2px;
  }

  ${({ isHidden }) =>
    !isHidden &&
    css`
      border-top: 2px solid black;
      border-left: 2px solid black;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    `}
`;

const Icon = styled.img`
  margin: 0px 0.4vw;
  width: 1.2rem;
`;

function OpenProgramTab({
  name,
  iconURL,
  onClick = noop,
  onMouseDown = noop,
  isHidden = false,
}) {
  return (
    <OpenProgramWrapper
      onClick={onClick}
      onMouseDown={onMouseDown}
      isHidden={isHidden}
    >
      <Icon src={iconURL} />
      <EllipsisText>{name}</EllipsisText>
    </OpenProgramWrapper>
  );
}

const RightSideMenu = styled(FlexboxRow)`
  position: relative;
  align-items: center;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  margin-right: 0.3vw;
  border-top: 2px solid grey;
  border-left: 2px solid grey;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  max-width: 170px;
`;

const Windows95MenuWrapper = styled.div`
  position: absolute;
  bottom: var(--navbar-height);
  background: var(--windows-tan);
  z-index: var(--z-navbar-layer);
  border-left: 2px solid white;
  border-top: 2px solid white;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
`;

const Windows95MenuSidebar = styled.div`
  height: 100%;
  font-size: 1.5rem;
  background: linear-gradient(to bottom, #000080, #1084d0);
  display: inline-block;
  width: 30px;
  bottom: 0;
  left: 0;
  position: absolute;
`;

const Windows95MenuHeadline = styled.div`
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  width: 150%;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 0px;
  color: white;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  margin-bottom: -25px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  user-select: none;
  padding-top: 12px;
  height: 38px;
  box-sizing: border-box;
  padding-left: 40px;
  font-size: 13px;
  margin-left: 25px;
  padding-right: 4px;
  background-size: auto 80%;
  background-position: 5px 50%;
  background-repeat: no-repeat;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
    `}

  :hover {
    background-color: darkblue;
    color: white;
  }
`;

function Windows95Menu({ programs = [], onOpenProgram = noop }) {
  const memoizedPrograms = useMemo(() => {
    return programs.map(({ name, title, icon, menuStyles, onClick }) => {
      return (
        <MenuItem
          key={name}
          icon={icon}
          style={menuStyles}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              onOpenProgram(name);
            }
          }}
        >
          {title}
        </MenuItem>
      );
    });
  }, [programs, onOpenProgram]);

  return (
    <Windows95MenuWrapper>
      <Windows95MenuSidebar>
        <Windows95MenuHeadline>
          <b>capalis</b>.ch
        </Windows95MenuHeadline>
      </Windows95MenuSidebar>
      <FlexboxColumn>{memoizedPrograms}</FlexboxColumn>
    </Windows95MenuWrapper>
  );
}

const VolumeButton = styled.img.attrs({
  className: "Windows95NavBar-VolumeButton",
  alt: "volume button",
})`
  cursor: pointer;
  height: 80%;
`;

const VOLUME_MIN = 0.0;
const VOLUME_MAX = 1.0;

const CLOSE_FULLSCREEN_TOGGLE_MS = 10000;

export default function Windows95NavBar({
  tabs = [],
  programs = [],
  onShowProgram = noop,
  onOpenSystemMenu = noop,
  onMinimizeProgram = noop,
  onOpenProgram = noop,
  isOverlaid = false,
}) {
  const [isWindowsMenuOpen, setIsWindowsMenuOpen] = useState(false);
  const [isVolumeControlOpen, setIsVolumeControlOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isGooglReviewsOpen, setIsGoogleReviewsOpen] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(1.0);
  const [googleReviewsTimeout, setGoogleReviewsTimeout] = useState();

  const { toggleLanguage, language } = useContext(LanguageContext);

  const audioPlayerRef = useRef(null);
  const menuRef = useRef(null);

  const openWindowsMenu = useCallback(() => {
    setIsWindowsMenuOpen(true);
  }, []);

  const closeWindowsMenu = useCallback(() => {
    setIsWindowsMenuOpen(false);
  }, []);

  const openVolumeMenu = useCallback(() => {
    setIsVolumeControlOpen(true);
  }, []);

  const closeVolumeMenu = useCallback(() => {
    setIsVolumeControlOpen(false);
  }, []);

  const closeToolTip = useCallback(() => {
    setIsToolTipOpen(false);
  }, []);

  const toggleFullScreen = useCallback(() => {
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const handleStartButtonClick = useCallback(() => {
    if (isWindowsMenuOpen) return closeWindowsMenu();
    else openWindowsMenu();
  }, [isWindowsMenuOpen, closeWindowsMenu, openWindowsMenu]);

  useEffect(() => {
    if (volumeLevel !== audioPlayerRef.current.volume) {
      audioPlayerRef.current.volume = clamp(
        volumeLevel,
        VOLUME_MIN,
        VOLUME_MAX
      );
    }
  });

  useEffect(() => {
    setTimeout(() => {
      closeToolTip();
    }, CLOSE_FULLSCREEN_TOGGLE_MS);
  }, [closeToolTip]);

  const handleVolumeButtonClick = useCallback(() => {
    if (isVolumeControlOpen) return closeVolumeMenu();
    else openVolumeMenu();
  }, [isVolumeControlOpen, closeVolumeMenu, openVolumeMenu]);

  const toggleMute = useCallback(() => {
    if (isMuted) {
      audioPlayerRef.current.play();
      setIsMuted(false);
      setVolumeLevel(1.0);
    } else {
      setIsMuted(true);
      setVolumeLevel(0);
    }
  }, [isMuted]);

  const EXTRA_MENU_ITEMS = useMemo(() => {
    return [
      {
        name:
          language === "DE"
            ? `Musik ${isMuted ? "On" : "Off"} `
            : `Music ${isMuted ? "On" : "Off"} `,
        title:
          language === "DE"
            ? `Musik ${isMuted ? "On" : "Off"} `
            : `Music ${isMuted ? "On" : "Off"} `,
        icon: AudioIcon,
        menuStyles: {
          backgroundSize: "auto 70%",
          backgroundPosition: "7px 50%",
        },
        onClick: toggleMute,
      },
      {
        name: language === "DE" ? "Kontakt" : "Contact",
        title: language === "DE" ? "Kontakt" : "Contact",
        icon: OutlookExpressIcon,
        menuStyles: {
          backgroundSize: "auto 65%",
          backgroundPosition: "8px 50%",
        },
        onClick: () => {
          onOpenSystemMenu(0);
          closeWindowsMenu();
        },
      },
      {
        name: "Legal",
        title: "Legal",
        icon: KeyLockIcon,
        menuStyles: {
          backgroundSize: "auto 70%",
          backgroundPosition: "7px 50%",
        },
        onClick: () => {
          onOpenSystemMenu(1);
          closeWindowsMenu();
        },
      },
    ];
  }, [isMuted, language, toggleMute, onOpenSystemMenu, closeWindowsMenu]);

  function handleOpenProgram(program) {
    closeWindowsMenu();
    onOpenProgram(program);
  }

  function showGoogleReviews() {
    if (googleReviewsTimeout) {
      clearTimeout(googleReviewsTimeout);
      setGoogleReviewsTimeout();
    }
    setIsGoogleReviewsOpen(true);
  }

  function hideGoogleReviews() {
    if (googleReviewsTimeout) return;
    else {
      const t = setTimeout(() => {
        setIsGoogleReviewsOpen(false);
      }, 250);
      setGoogleReviewsTimeout(t);
    }
  }

  useOutsideClick(menuRef, closeWindowsMenu, { useMouseDown: true });

  return (
    <Wrapper ref={menuRef} isOverlaid={isOverlaid}>
      {isWindowsMenuOpen && (
        <Windows95Menu
          onOpenProgram={handleOpenProgram}
          programs={EXTRA_MENU_ITEMS.concat(programs)}
        />
      )}

      <FlexboxRow style={{ overflow: "hidden" }} isCentered>
        <StartButton
          onMouseDown={handleStartButtonClick}
          isWindowsMenuOpen={isWindowsMenuOpen}
        >
          <img src={WINDOWS_95_LOGO} alt="Windows 95 Flying Window logo" />
          <div>Start</div>
        </StartButton>

        {!_.isEmpty(tabs) &&
          tabs.map(({ name, isHidden, icon, title }) => {
            const handleClick = isHidden
              ? () => onShowProgram(name)
              : () => onMinimizeProgram(name);
            return (
              <OpenProgramTab
                key={name}
                name={title}
                iconURL={icon}
                onMouseDown={handleClick}
                isHidden={isHidden}
              />
            );
          })}
      </FlexboxRow>

      <audio ref={audioPlayerRef} loop muted={isMuted}>
        <source src={SAPPHIRE_MP3} type="audio/mp3" />
        <source src={SAPPHIRE_WAV} type="audio/wav" />
      </audio>

      <RightSideMenu>
        <Localization language={language} onClick={toggleLanguage} />
        {isGooglReviewsOpen && (
          <GoogleReviews
            language={language}
            onMouseEnter={showGoogleReviews}
            onMouseLeave={() => setIsGoogleReviewsOpen(false)}
          />
        )}
        <Antivirus
          onMouseEnter={showGoogleReviews}
          onMouseLeave={hideGoogleReviews}
          onClick={() => {
            showGoogleReviews();
            onOpenSystemMenu(1);
          }}
        />
        <FullscreenToggle
          isFullscreen={isFullscreen}
          onClick={toggleFullScreen}
        />
        {isToolTipOpen && (
          <Windows95ToolTip
            message={
              language === "DE"
                ? "Hier klicken für Vollbild"
                : "Click here for fullscreen"
            }
            onClose={closeToolTip}
          />
        )}
        {isVolumeControlOpen && (
          <VolumeControlPanel
            isMuted={isMuted}
            volumeLevel={volumeLevel}
            onChangeVolume={setVolumeLevel}
            onClickMute={toggleMute}
            close={closeVolumeMenu}
          />
        )}
        <VolumeButton
          onClick={handleVolumeButtonClick}
          src={isMuted ? VolumeOff : VolumeOn}
          role="button"
        />
        <Clock />
      </RightSideMenu>
    </Wrapper>
  );
}
