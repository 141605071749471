import styled from 'styled-components'

const EllipsisText = styled.div.attrs({
  className: 'EllipsisText'
})`
  text-overflow: ellipsis;
  overflow     : hidden;
`

export default EllipsisText
