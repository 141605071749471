import React from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import noop from 'lodash/noop'

import {
  Redirect,
  withRouter
} from 'react-router-dom'
import ReactMarkdown from 'react-markdown/with-html'

import Windows95Program from 'components/Windows95Program'
import ContentPage from 'containers/ContentPage'

import NotepadIcon from 'assets/img/windows-95/notepad.ico'

/**
 * Traverses my pages directory and loads all the md files in there!
 */
export const ALL_BLOG_POSTS = require.context('pages', true /* use subdirs */ , /\.md$/)
  .keys()
  .map(key => {
    // key is of format `./my-article.md`
    const [, fileName] = key.split('/')
    const [articleName] = fileName.split('.md')
    return {
      key,
      fileName,
      articleName
    }
  })
  .map(({
    key,
    fileName,
    articleName
  }) => {
    try {
      const { body, attributes } = JSON.parse(require(`pages/${fileName}`))
      return { articleName, body, ...attributes }
    } catch (e) {
      console.error(`Failed to load a markdown file with filename ${fileName} -- ${e.message}`)
      return
    }
  })
  .sort((firstEl, secondEl) => {
    const firstDate = moment(firstEl.date)
    const secondDate = moment(secondEl.date)
    if (firstDate.isBefore(secondDate)) return 1
    else if (firstDate.isAfter(secondDate)) return -1
    else return 0
  })

function BlogPost({
  match: { params },
  isHidden,
  isMaximized,
  icon = NotepadIcon,
  hideProgram = noop,
  maximizeProgram = noop,
  minimizeProgram = noop,
  onClose = noop,
}) {
  const postIndex = ALL_BLOG_POSTS.findIndex(({ articleName }) => articleName === params.post)
  const post = ALL_BLOG_POSTS[postIndex]

  if (!post) return <Redirect to="/" />

  const { body, date, title, description, tags } = post

  return (
    <Windows95Program
      url={params.post}
      isHidden={isHidden}
      isMaximized={isMaximized}
      maximizeProgram={maximizeProgram}
      hideProgram={hideProgram}
      minimizeProgram={minimizeProgram}
      onClose={onClose}
      icon={icon}
    >
      <Helmet>
        <title>{title ? `${title}` : 'Capalis – wir haben die besten Jobs für qualifizierte IT Spezialisten!'}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={description} />
        <meta
          name="description"
          content={description}
        />
      </Helmet>
      <ContentPage
        tags={tags}
        date={moment(date).format('MMM DD YYYY')}
        previous={ALL_BLOG_POSTS[postIndex + 1] && ALL_BLOG_POSTS[postIndex + 1]}
        next={ALL_BLOG_POSTS[postIndex - 1] && ALL_BLOG_POSTS[postIndex - 1]}
      >
        <ReactMarkdown
          escapeHtml={false}
          source={body}
        />
      </ContentPage>
    </Windows95Program>
  )
}

export default withRouter(BlogPost)
